import { Delete, Edit, Refresh } from '@material-ui/icons'; //MoreHoriz
import ColorAvatar from 'components/ColorAvatar/ColorAvatar';
import Select from 'components/Form/Select';
import Single from 'components/Form/Single';
import Multi from 'components/Form/Multi';
import SimpleListTab from 'components/SimpleListTab';
import DetailsPage from 'pages/DetailsPage';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as action from 'redux/actions';
import RgbSlider from '../../components/ColorSlider/RgbSlider';
import SingleSlider from '../../components/ColorSlider/SingleSlider';

const NailDetailPage = ({ data, dispatch, formData, page, total, rowsPerPage, shadesIndex }) => {
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    dispatch(action.fetchProduct(id));
    return () => {
      dispatch(action.resetProduct());
    };
  }, [dispatch, id]);

  const config = {
    category: 'products',
    categoryHierarchy: ['masterdata', 'products', 'makeup', 'nail'],
    modals: [
      {
        name: 'editProduct',
        fields: [
          {
            name: 'part',
            autoFocus: true,
            component: Single,
            label: 'Part',
            required: true,
            readonly: true,
          },
          {
            name: 'name',
            autoFocus: true,
            component: Single,
            label: 'Name',
            required: true,
          },
          {
            name: 'range',
            autoFocus: true,
            component: Single,
            label: 'Range',
            required: true,
          },
          {
            name: 'shadeName',
            autoFocus: true,
            component: Single,
            label: 'ShadeName',
            required: true,
          },
          {
            name: 'texture',
            component: Select,
            label: 'Texture',
            options: [
              { value: '--', text: ' --' },
              { value: 'Liquid', text: 'Liquid' },
              { value: 'Cream', text: 'Cream' },
              { value: 'Powder', text: 'Powder' },
            ],
            required: true,
          },
          {
            name: 'finish',
            component: Select,
            label: 'Finish',
            options: [
              { value: '--', text: ' --' },
              { value: 'Matte', text: 'Matte' },
              { value: 'Glossy', text: 'Glossy' },
              { value: 'Glitter', text: 'Glitter' },
              { value: 'Shiny', text: 'Shiny' },
            ],
            required: true,
          },
          {
            name: 'allowedAudiences',
            autoFocus: true,
            component: Multi,
            label: 'Allowed Audiences',
            required: true,
          },
          {
            name: 'allowedStyles',
            autoFocus: true,
            component: Multi,
            label: 'Allowed Styles',
            required: true,
          },
          {
            name: 'version',
            autoFocus: true,
            component: Single,
            label: 'Version',
            required: true,
            readonly: true,
          },
        ],
        onSubmit: (submitData) => {
          dispatch(action.editProduct(submitData, 'details'));
        },
        title: 'Edit Nail',
      },
      {
        name: 'deleteProduct',
        contentText: 'Are you sure to delete the product?',
        fields: [
          {
            name: 'name',
            component: Single,
            label: 'Name',
            readonly: true,
          },
        ],
        onSubmit: () => {
          dispatch(
            action.deleteProduct(id, {}, () => {
              history.push('/nail');
            }),
          );
        },
        title: 'Delete',
      },
      {
        name: 'releaseProduct',
        contentText: `Are you sure to ${data.releaseDate === '' ? 'release' : 'unreleaseable'} the product?`,
        fields: [
          {
            name: 'product',
            component: Single,
            label: 'Product',
            readonly: true,
          },
        ],
        onSubmit: (data) => {
          dispatch(
            action.editProduct({ ...data, releaseDate: data.releaseDate === '' ? new Date().toJSON() : '' }, 'details'),
          );
        },
        title: 'Release Product',
      },
      {
        name: 'editProductShade',
        fields: [
          {
            name: 'rgbColor',
            component: RgbSlider,
          },
          {
            name: 'strength',
            component: SingleSlider,
            label: 'Strength',
            maxValue: 100,
            minValue: 0,
            stepValue: 1,
            value: formData.strength,
          },
        ],
        onSubmit: () => {
          const { ...restData } = formData;
          const index = shadesIndex;
          const { shades, ...restProduct } = data;
          const newShades = shades.slice(0);
          newShades[index] = restData;
          restProduct.index = index;
          dispatch(action.editProductShade({ shades: newShades, ...restProduct }, 'details'));
        },
        title: 'Edit Shade',
      },
    ],
    tabs: [
      {
        name: 'general',
        label: 'General',
        fields: [
          { name: 'guid', label: 'Guid' },
          { name: 'part', label: 'Part' },
          { name: 'name', label: 'Name' },
          { name: 'range', label: 'Range' },
          { name: 'finish', label: 'Finish' },
          { name: 'shadeName', label: 'ShadeName' },
          { name: 'texture', label: 'Texture' },
          { name: 'allowedStyles', label: 'Allowed Styles' },
          { name: 'allowedAudiences', label: 'Allowed Audiences' },
          { name: 'version', label: 'Version' },
          { name: 'createdDate', label: 'Created Date' },
          { name: 'lastModifiedDate', label: 'Last Modified Date' },
        ],
      },
      {
        name: 'shades',
        label: 'Shades',
        component: SimpleListTab,
        columns: [
          {
            name: 'action',
            actions: [
              {
                name: 'shadesEdit',
                icon: Edit,
                onClick: (row) => {
                  const index = data.shades.indexOf(row);
                  const indexId = id + '#' + index;
                  dispatch(action.setSelectedShadesIndex(index));
                  dispatch(action.openEditProductShadeModal(indexId));
                },
                title: 'Shades Edit',
              },
            ],
          },
          { name: 'rgbColor', component: ColorAvatar, text: 'Color', alpha: 'strength' },
          { name: 'strength', text: 'Strength', minWidth: 200 },
        ],
      },
    ],
    title: 'Nail Details',
    toolbarItems: [
      {
        name: 'refresh',
        icon: Refresh,
        onClick: () => {
          dispatch(action.fetchProduct(id));
        },
        text: 'Refresh',
      },
      {
        name: 'edit',
        icon: Edit,
        onClick: () => {
          dispatch(action.openEditProductModal(id));
        },
        text: 'Edit',
      },
      {
        name: 'delete',
        icon: Delete,
        onClick: () => {
          dispatch(action.openDeleteProductModal(data));
        },
        text: 'Delete',
      },
      // {
      //   name: data.releaseDate === '' ? 'release' : 'unreleaseable',
      //   icon: data.releaseDate === '' ? ToggleOn : ToggleOff,
      //   onClick: () => {
      //     dispatch(action.openReleaseProductModal(id));
      //   },
      //   text: data.releaseDate === '' ? 'release' : 'unreleaseable',
      //   disabled: !!data.releaseDate,
      // },
    ],
  };

  return <DetailsPage config={config} data={data} total={total} page={page} rowsPerPage={rowsPerPage} />;
};

const mapStateToProps = ({ product, formData, shades }) => ({
  formData: formData,
  data: product,
  page: 0,
  rowsPerPage: shades.total,
  total: shades.total,
  shadesIndex: shades.selectedShadesIndex,
});

export default connect(mapStateToProps)(NailDetailPage);
