import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}));

export default function TabPanel(props) {
  const classes = useStyles();
  const { children, hidden } = props;
  return (
    <div className={classes.root} hidden={hidden}>
      {children}
    </div>
  );
}
