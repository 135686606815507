import { useMsal } from '@azure/msal-react';
import { Box, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Copyright from 'components/Copyright/Copyright';
import { loginRequest } from 'config';
import SignInButton from 'pages/Login/SignInButton';
import SignOutButton from 'pages/Login/SignOutButton';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

const LoginPage = withStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(0, 2),
    height: '100vh',
  },
  progress: {},
}))(({ classes, isAuthenticated, location }) => {
  const [loading, setLoading] = React.useState(true);
  const { instance } = useMsal();
  let timer;

  useEffect(() => {
    timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const login = () => {
    setLoading(true);
    instance
      .loginRedirect({
        ...loginRequest,
        redirectUri: `${process.env.REACT_APP_REDIRECT_URI}`,
        state: JSON.stringify({ redirect: location.state || { pathname: '/' } }),
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box className={classes.root}>
      <Box fontSize="h5.fontSize" fontWeight={300} mb={5} mt={12} textAlign="center">
        Welcome to AR-Makeup Console
      </Box>
      <Box flexGrow={1} textAlign="center">
        {loading ? (
          <CircularProgress size={24} className={classes.progress} />
        ) : isAuthenticated ? (
          <SignOutButton />
        ) : (
          <SignInButton login={login} />
        )}
      </Box>
      <Copyright />
    </Box>
  );
});

const mapStateToProps = ({ loginInfo }) => ({
  isAuthenticated: !!loginInfo.activeAccount,
});

export default connect(mapStateToProps)(LoginPage);
