const initialState = {
  results: [],
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_SDKVERSIONS': {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};
