import { Delete, Edit, Refresh } from '@material-ui/icons';
import Single from 'components/Form/Single';
import Multi from 'components/Form/Multi';
import Select from 'components/Form/Select';
import DetailsPage from 'pages/DetailsPage';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as action from 'redux/actions';
import * as yup from 'yup';

const AudienceDetailsPage = ({ data, dispatch, sdkVersions }) => {
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    dispatch(action.fetchAudience(id));
    dispatch(action.fetchSDKversions());
    return () => {
      dispatch(action.resetAudience());
    };
  }, [dispatch, id]);

  const config = {
    category: 'system',
    modals: [
      {
        name: 'editAudience',
        fields: [
          {
            name: 'name',
            autoFocus: true,
            component: Single,
            label: 'Name',
            required: true,
            validation: yup.string().required('Name is required').min(2, 'Name should be of minimum 2 characters'),
          },
          {
            component: Multi,
            label: 'Allowed Origins',
            name: 'allowedOrigins',
            required: true,
            validation: yup.string().required('Allowed Origins is required'),
          },
          {
            component: Single,
            label: 'AEP Script Source',
            name: 'aepScriptSrc',
          },
          {
            component: Select,
            label: 'SDK Version',
            name: 'scope',
            required: true,
            options: sdkVersions,
            validation: yup.string().required('SDK Version is required'),
          },
        ],
        onSubmit: (data) => {
          dispatch(action.editAudience(data, 'details'));
        },
        title: 'Edit',
      },
      {
        name: 'deleteAudience',
        contentText: 'Are you sure to delete the audience?',
        fields: [
          {
            name: 'name',
            component: Single,
            label: 'Name',
            readonly: true,
          },
        ],
        onSubmit: () => {
          dispatch(
            action.deleteAudience(id, {}, () => {
              history.push('/system/audiences');
            }),
          );
        },
        title: 'Delete',
      },
    ],
    tabs: [
      {
        name: 'general',
        label: 'General',
        fields: [
          { name: 'id', label: 'Id' },
          { name: 'name', label: 'Name' },
          { name: 'allowedOrigins', label: 'Allowed Origins', multiline: true },
          { name: 'clientKey', label: 'Client Key' },
          { name: 'aepScriptSrc', label: 'AEP Script Source' },
          { name: 'scope', label: 'SDK Version' },
          { name: 'createdDate', label: 'Created Date' },
          { name: 'lastModifiedDate', label: 'Last Modified Date' },
        ],
      },
    ],
    title: 'Audience Details',
    toolbarItems: [
      {
        name: 'refresh',
        icon: Refresh,
        onClick: () => {
          dispatch(action.fetchAudience(id));
        },
        text: 'Refresh',
      },
      {
        name: 'edit',
        icon: Edit,
        onClick: () => {
          dispatch(action.openEditAudienceModal(id));
        },
        text: 'Edit',
      },
      {
        name: 'delete',
        icon: Delete,
        onClick: () => {
          dispatch(action.openDeleteAudienceModal(data));
        },
        text: 'Delete',
      },
    ],
  };

  return <DetailsPage config={config} data={data} />;
};

const mapStateToProps = ({ audience, sdkVersions }) => ({
  data: audience,
  sdkVersions: sdkVersions.results,
});

export default connect(mapStateToProps)(AudienceDetailsPage);
