import { Snackbar } from '@material-ui/core';
import { alertAutoHideDuration } from 'config';
import React from 'react';
import { connect } from 'react-redux';
import * as action from 'redux/actions';

const Alert = ({ dispatch, message }) => {
  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      autoHideDuration={alertAutoHideDuration}
      message={message}
      onClose={() => {
        dispatch(action.hideAlert());
      }}
      open={!!message}
    />
  );
};

const mapStateToProps = ({ alert }) => ({
  message: alert.message,
  severity: alert.severity,
});

export default connect(mapStateToProps)(Alert);
