import { get } from 'api';
export const fetchRequestAmount = (startDate, endDate) => {
  const query = `?startDate=${startDate}&endDate=${endDate}&type=days`;
  return get(`/dashboard/requestByFilter${query}`);
};

export const fetchLast = (startDate, endDate) => {
  const query = `?startDate=${startDate}&endDate=${endDate}&type=last`;
  return get(`/dashboard/requestByFilter${query}`);
};

export const fetchTop = (startDate, endDate) => {
  const query = `?startDate=${startDate}&endDate=${endDate}&type=top`;
  return get(`/dashboard/requestByFilter${query}`);
};
