import React, { useEffect } from 'react';
import NavIndexPage from '../NavIndexPage';
import { AccountTree } from '@material-ui/icons';

const Reports = () => {
  useEffect(() => {
    document.title = 'AR-Makeup Console';
  });

  const config = {
    category: 'analytics',
    title: 'Analytics',
    items: [
      {
        name: 'Report 1',
        title: 'Report 1',
        description: 'View report 1 here.',
        icon: AccountTree,
        link: '/',
      },
      {
        name: 'Report 2',
        title: 'Report 2',
        description: 'View report 2 here.',
        icon: AccountTree,
        link: '/',
      },
      {
        name: 'Report 3',
        title: 'Report 3',
        description: 'View report 3 here.',
        /* icon: AccountTree, */
        link: '/',
      },
    ],
  };

  return <NavIndexPage config={config}>Analytics Index Page</NavIndexPage>;
};

export default Reports;
