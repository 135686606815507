const initialState = {
  top: { labels: [], data: [] },
  last: { labels: [], data: [] },
  amount: { byProduct: 0, byAudience: 0 },
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_LAST': {
      return { ...state, last: payload };
    }
    case 'SET_TOP': {
      return { ...state, top: payload };
    }
    case 'SET_AUDIENCES_AMOUNT': {
      state.amount.byAudience = payload;

      return { ...state };
    }
    case 'SET_PRODUCT_AMOUNT': {
      state.amount.byProduct = payload;
      return { ...state };
    }
    default:
      return state;
  }
};
