import { withStyles } from '@material-ui/core/styles';
import DefaultLayout from './../layouts/DefaultLayout';
import React from 'react';
import Breadcrumb from './../components/Breadcrumb/BreadCrumb';
import CardTile from 'components/CardTile/CardTile';
import { Grid } from '@material-ui/core';

const NavIndexPage = withStyles((theme) => ({
  cardContainer: {
    marginTop: theme.spacing(2),
  },
}))(({ classes, config: { ...rest } }) => {
  return (
    <DefaultLayout {...rest}>
      <Breadcrumb></Breadcrumb>
      <Grid className={classes.cardContainer} container spacing={2}>
        {rest.items.map((item, i) => {
          return (
            <Grid item md={item.span || 6} key={`${item.title}${i}`}>
              <CardTile card={item} icon={item.icon}></CardTile>
            </Grid>
          );
        })}
      </Grid>
    </DefaultLayout>
  );
});

export default NavIndexPage;
