import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FormModal from 'components/FormModal/FormModal';
import Tab from 'components/SimpleTab/SimpleTab';
import DefaultLayout from 'layouts/DefaultLayout';
import React from 'react';
import Breadcrumb from './../components/Breadcrumb/BreadCrumb';

const DetailsPage = withStyles((theme) => ({
  toolbar: {
    marginLeft: theme.spacing(0),
  },
  button: {
    margin: theme.spacing(2, 2, 2, 0),
  },
}))(({ classes, config: { modals = [], tabs, toolbarItems, ...rest }, data, ...restList }) => {
  return (
    <DefaultLayout {...rest}>
      <Breadcrumb data={data} isDetails="true"></Breadcrumb>

      <div className={classes.toolbar}>
        {toolbarItems.map(({ disabled, icon: Icon, name, text, ...rest }) => {
          return (
            <Button
              className={classes.button}
              color="primary"
              key={name}
              startIcon={<Icon />}
              variant="contained"
              disabled={disabled}
              {...rest}
            >
              {text}
            </Button>
          );
        })}
      </div>
      <Tab data={data} tabs={tabs} {...restList} />
      {modals.map(({ name, ...rest }) => {
        return <FormModal key={name} name={name} {...rest} />;
      })}
    </DefaultLayout>
  );
});

export default DetailsPage;
