import React, { useEffect } from 'react';
import DefaultLayout from 'layouts/DefaultLayout';

const UserIndexPage = () => {
  useEffect(() => {
    document.title = 'AR-Makeup Console';
  });
  return <DefaultLayout category="system" title="Users"></DefaultLayout>;
};

export default UserIndexPage;
