import * as api from 'api';
import * as action from 'redux/actions';

let flag = true;

export const setLast = (data) => ({
  type: 'SET_LAST',
  payload: data,
});

export const setTop = (data) => ({
  type: 'SET_TOP',
  payload: data,
});

export const setAudiencesAmount = (data) => ({
  type: 'SET_AUDIENCES_AMOUNT',
  payload: data,
});

export const setProductAmount = (data) => ({
  type: 'SET_PRODUCT_AMOUNT',
  payload: data,
});

// export const getRequestsAmount = () => (dispatch) => {
//   api
//     .getRequestAmount()
//     .then((response) => {
//       dispatch(action.setRequestsAmount(response.data));
//       return Promise.resolve();
//     })
//     .then(() => {
//       flag = false;
//     })
//     .catch((error) => {
//       dispatch(action.setError(error));
//       flag = false;
//     });
// };

export const getLast = (startDate, endDate) => (dispatch) => {
  dispatch(action.showLoading());
  api
    .fetchLast(startDate, endDate)
    .then((response) => {
      // response.data.config = JSON.stringify(response.data.config);
      dispatch(setLast({ labels: response.data.labels, data: response.data.data }));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const getTop = (startDate, endDate) => (dispatch) => {
  if (flag) {
    dispatch(action.showLoading());
    api
      .fetchTop(startDate, endDate)
      .then((response) => {
        // response.data.config = JSON.stringify(response.data.config);
        dispatch(setTop({ labels: response.data.labels, data: response.data.data }));
        return Promise.resolve();
      })
      .catch((error) => {
        dispatch(action.setError(error));
      })
      .finally(() => {
        dispatch(action.hideLoading());
      });
  }
};

export const getAudiencesAmount = () => (dispatch) => {
  dispatch(action.showLoading());
  api
    .fetchAudiencesAmount()
    .then((response) => {
      // response.data.config = JSON.stringify(response.data.config);
      dispatch(setAudiencesAmount(response.data));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const getProductAmount = () => (dispatch) => {
  dispatch(action.showLoading());
  api
    .fetchProductAmount()
    .then((response) => {
      // response.data.config = JSON.stringify(response.data.config);
      dispatch(setProductAmount(response.data));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};
