import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: props.location,
            }}
          />
        )
      }
      {...rest}
    />
  );
};

const mapStateToProps = ({ loginInfo }) => ({
  isAuthenticated: !!loginInfo.activeAccount,
});

export default connect(mapStateToProps)(PrivateRoute);
