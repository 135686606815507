import React from 'react';
import { TextField } from '@material-ui/core';

export default (props) => {
  const { data, field, onChange, formik, onBlur, error, helperText, value } = props;

  const handleChange = (e) => {
    formik.handleChange(e);

    onChange(e.target.value);
  };

  return (
    <TextField
      autoFocus={field.autoFocus}
      fullWidth
      helperText={error ? helperText : field.helperText}
      id={field.name}
      inputProps={{ readOnly: field.readonly }}
      label={field.label}
      margin="normal"
      name={field.name}
      onChange={handleChange}
      onBlur={onBlur}
      required={field.required}
      value={field.maxLength ? data[field.name].substring(1, field.maxLength) : data[field.name] || value}
      variant={field.variant || 'standard'}
      error={error}
    />
  );
};
