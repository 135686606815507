import { Avatar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// import classNames from 'classnames';
import React from 'react';

const ColorAvatar = withStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    border: '1px solid #DCDCDC',
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  left: {},
  right: { padding: '3px 0 0 3px' },
}))(({ classes, data, field }) => {
  return (
    <div className={classes.root}>
      <Avatar
        style={{
          backgroundColor: `rgb(${data[field.name][0] + ',' + data[field.name][1] + ',' + data[field.name][2]})`,
        }}
        className={classes.small}
      >
        {''}
      </Avatar>
      <div className={classes.right}>{`${
        'R: ' + data[field.name][0] + ', ' + 'G: ' + data[field.name][1] + ', ' + 'B: ' + data[field.name][2]
      }`}</div>
    </div>
  );
});

export default ColorAvatar;
