import { useMsal } from '@azure/msal-react';
import { Button } from '@material-ui/core';
import React from 'react';

const SignOutButton = () => {
  const { instance } = useMsal();

  const signOut = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  };

  return (
    <Button color="primary" variant="contained" onClick={signOut}>
      Sign out from Azure AD
    </Button>
  );
};

export default SignOutButton;
