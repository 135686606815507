import { createTheme, ThemeProvider } from '@material-ui/core';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import AudienceDetailsPage from 'pages/Audience/AudienceDetailsPage';
import AudienceIndexPage from 'pages/Audience/AudienceIndexPage';
import BlushDetailPage from 'pages/Blush/BlushDetailPage';
import BlushIndexPage from 'pages/Blush/BlushIndexPage';
import ContourDetailPage from 'pages/Contour/ContourDetailPage';
import ContourIndexPage from 'pages/Contour/ContourIndexPage';
import DashboardPage from 'pages/Dashboard/DashboardPage';
import EyebrowDetailPage from 'pages/Eyebrow/EyebrowDetailPage';
import EyebrowIndexPage from 'pages/Eyebrow/EyebrowIndexPage';
import EyeshadowDetailPage from 'pages/Eyeshadow/EyeshadowDetailPage';
import EyeshadowIndexPage from 'pages/Eyeshadow/EyeshadowIndexPage';
import FoundationDetailPage from 'pages/Foundation/FoundationDetailPage';
import FoundationIndexPage from 'pages/Foundation/FoundationIndexPage';
import HaircolorDetailPage from 'pages/Haircolor/HaircolorDetailPage';
import HaircolorIndexPage from 'pages/Haircolor/HaircolorIndexPage';
import LiplinerDetailPage from 'pages/Lipliner/LiplinerDetailPage';
import LiplinerIndexPage from 'pages/Lipliner/LiplinerIndexPage';
import LipstickDetailPage from 'pages/Lipstick/LipstickDetailPage';
import LipstickIndexPage from 'pages/Lipstick/LipstickIndexPage';
import LoginPage from 'pages/Login/LoginPage';
import MascaraDetailPage from 'pages/Mascara/MascaraDetailPage';
import MascaraIndexPage from 'pages/Mascara/MascaraIndexPage';
import NailDetailPage from 'pages/Nail/NailDetailPage';
import NailIndexPage from 'pages/Nail/NailIndexPage';
import NotificationIndexPage from 'pages/Notification/NotificationIndexPage';
import ReportIndexPage from 'pages/Report/ReportIndexPage';
import StamplinerDetailPage from 'pages/Stampliner/StamplinerDetailPage';
import StamplinerIndexPage from 'pages/Stampliner/StamplinerIndexPage';
import UserIndexPage from 'pages/User/UserIndexPage';
import SystemIndexPage from 'pages/System/SystemIndexPage';
import MasterDataIndexPage from 'pages/MasterData/MasterDataIndexPage';
import StyleIndexPage from 'pages/Style/StyleIndexPage';
import StyleDetailsPage from 'pages/Style/StyleDetailsPage';
import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import EyecolorIndexPage from 'pages/Eyecolor/EyecolorIndexPage';
import EyecolorDetailPage from 'pages/Eyecolor/EyecolorDetailPage';
import EyelinerIndexPage from 'pages/Eyeliner/EyelinerIndexPage';
import EyelinerDetailPage from 'pages/Eyeliner/EyelinerDetailPage';
// import ProductIndexPage from 'pages/Product/ProductIndexPage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
  },
  typography: {
    fontFamily: '"Helvetica Neue", "Helvetica", "Arial", sans-serif',
    button: {
      textTransform: 'none',
    },
  },
});

const App = ({ history }) => {
  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <PrivateRoute path="/system/audiences/:id" component={AudienceDetailsPage} />
          <PrivateRoute path="/system/audiences" component={AudienceIndexPage} />
          <PrivateRoute path="/blush/:id" component={BlushDetailPage} />
          <PrivateRoute exact path="/blush" component={BlushIndexPage} />
          <PrivateRoute path="/contour/:id" component={ContourDetailPage} />
          <PrivateRoute exact path="/contour" component={ContourIndexPage} />
          <PrivateRoute exact path="/" component={DashboardPage} />
          <PrivateRoute path="/eyebrow/:id" component={EyebrowDetailPage} />
          <PrivateRoute exact path="/eyebrow" component={EyebrowIndexPage} />
          <PrivateRoute exact path="/lipliner" component={LiplinerIndexPage} />
          <PrivateRoute exact path="/lipstick" component={LipstickIndexPage} />
          <PrivateRoute exact path="/haircolor" component={HaircolorIndexPage} />
          <PrivateRoute path="/lipliner/:id" component={LiplinerDetailPage} />
          <PrivateRoute path="/lipstick/:id" component={LipstickDetailPage} />
          <PrivateRoute path="/haircolor/:id" component={HaircolorDetailPage} />
          <PrivateRoute exact path="/nail" component={NailIndexPage} />
          <PrivateRoute path="/nail/:id" component={NailDetailPage} />
          <PrivateRoute exact path="/eyecolor" component={EyecolorIndexPage} />
          <PrivateRoute exact path="/eyecolor/:id" component={EyecolorDetailPage} />
          <PrivateRoute exact path="/eyeliner" component={EyelinerIndexPage} />
          <PrivateRoute exact path="/eyeliner/:id" component={EyelinerDetailPage} />
          <PrivateRoute exact path="/eyeshadow" component={EyeshadowIndexPage} />
          <PrivateRoute path="/eyeshadow/:id" component={EyeshadowDetailPage} />
          <PrivateRoute exact path="/foundation" component={FoundationIndexPage} />
          <PrivateRoute path="/foundation/:id" component={FoundationDetailPage} />
          <PrivateRoute exact path="/mascara" component={MascaraIndexPage} />
          <PrivateRoute path="/mascara/:id" component={MascaraDetailPage} />
          <PrivateRoute exact path="/stampliner" component={StamplinerIndexPage} />
          <PrivateRoute path="/stampliner/:id" component={StamplinerDetailPage} />
          <PrivateRoute path="/reports" component={ReportIndexPage} />
          <PrivateRoute exact path="/master data" component={MasterDataIndexPage} />
          <PrivateRoute exact path="/master data/styles" component={StyleIndexPage} />
          <PrivateRoute path="/master data/styles/:id" component={StyleDetailsPage} />
          <PrivateRoute path="/notification" component={NotificationIndexPage} />
          <PrivateRoute path="/user" component={UserIndexPage} />
          <PrivateRoute path="/system" component={SystemIndexPage} />
          {/* <PrivateRoute exact path="/products" component={ProductIndexPage} /> */}
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
