const initialState = {
  results: [],
  searchText: '',
  total: 0,
  limit: 0,
  offset: 0,
  page: 0,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_STYLES': {
      return { ...state, ...payload };
    }
    case 'SET_STYLES_FILTER': {
      return { ...state, searchText: payload };
    }
    case 'SET_STYLES_PAGE': {
      return { ...state, page: payload };
    }
    default:
      return state;
  }
};
