import { Button, OutlinedInput, InputAdornment } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import FormModal from 'components/FormModal/FormModal';
import SimpleList from 'components/SimpleList/SimpleList';
import DefaultLayout from 'layouts/DefaultLayout';
import React from 'react';
import Breadcrumb from './../components/Breadcrumb/BreadCrumb';
import ClearIcon from '@material-ui/icons/Clear';

const IndexPage = withStyles((theme) => ({
  toolbar: {
    marginLeft: theme.spacing(0),
  },
  zeroPadding: {
    padding: 0,
  },
  button: {
    margin: theme.spacing(2, 2, 2, 0),
  },
  inputRoot: {
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.common.white,
    margin: theme.spacing(0, 2, 0, 0),
    position: 'relative',
    top: theme.spacing(1),
  },
  inputInput: {
    padding: theme.spacing(1.1, 1, 1.1, 0.25),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}))(
  ({
    classes,
    config: { columns = [], list: List = SimpleList, modals = [], toolbarItems = [], ...rest },
    data,
    ...restList
  }) => {
    return (
      <DefaultLayout {...rest}>
        <Breadcrumb></Breadcrumb>
        <div className={classes.toolbar}>
          {toolbarItems.map(({ icon: Icon, name, text, type, value, clearValue, ...rest }) => {
            return type === 'text' ? (
              <OutlinedInput
                key={name}
                placeholder={text}
                value={value}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': name }}
                {...rest}
                startAdornment={
                  <InputAdornment position="start">
                    <Icon />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton className={classes.zeroPadding} onClick={() => clearValue()}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            ) : (
              <Button
                className={classes.button}
                color="primary"
                key={name}
                startIcon={<Icon />}
                variant="contained"
                {...rest}
              >
                {text}
              </Button>
            );
          })}
        </div>
        <List columns={columns} data={data} {...restList} />
        {modals.map(({ name, ...rest }) => {
          return <FormModal key={name} name={name} {...rest} />;
        })}
      </DefaultLayout>
    );
  },
);

export default IndexPage;
