import { del, get, post, put } from 'api';
import _ from 'lodash';

export const createAudience = (data) => {
  const allowedOrigins = _.split(data.allowedOrigins, '\n').map((item) => item.trim());
  return post('/audiences', { ...data, allowedOrigins });
};

export const deleteAudience = (id) => {
  return del(`/audiences/${id}`);
};

export const editAudience = ({ id, ...rest }) => {
  const allowedOrigins = _.split(rest.allowedOrigins, '\n').map((item) => item.trim());
  delete rest.lastModifiedDate;
  delete rest.createdDate;
  delete rest.clientKey;
  return put(`/audiences/${id}`, { ...rest, allowedOrigins });
};

export const getAudience = (id) => {
  return get(`/audiences/${id}`);
};

export const getAudiences = (pageParams) => {
  const query = pageParams
    ? `?page=${pageParams.page ? pageParams.page + 1 : 1}&pageSize=${
        pageParams.pageSize ? pageParams.pageSize : 10
      }&name=${pageParams.name !== undefined ? pageParams.name : ''}`
    : '';
  return get(`/audiences${query}`);
};

export const fetchAudiencesAmount = () => {
  return get(`/audiences/amount`);
};
