import { Card, CardContent, CardActionArea, Typography /* Button */ } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
//import { AccessTime } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const CardTile = withStyles((theme) => ({
  cardContent: {
    display: 'flex',
    padding: theme.spacing(0),
  },
  textContent: {
    padding: theme.spacing(2),
    width: '100%',
  },
  icon: {
    backgroundColor: theme.palette.grey[200],
    minWidth: '20%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
  },
}))(({ classes, card, icon: Icon }) => {
  return (
    <Card variant="outlined">
      <CardActionArea component={Link} to={card.link || '/'}>
        <CardContent className={classes.cardContent}>
          {Icon && (
            <div className={classes.icon} xs="5">
              <Icon />
            </div>
          )}
          <div className={classes.textContent}>
            <Typography className={classes.title} variant="h6">
              {card.title}
            </Typography>
            <Typography>{card.description}</Typography>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
});
export default CardTile;
