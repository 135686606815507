import { Add, Delete, Edit, MoreHoriz, Refresh, Search } from '@material-ui/icons';
import Single from 'components/Form/Single';
import Multi from 'components/Form/Multi';
import Select from 'components/Form/Select';
import IndexPage from 'pages/IndexPage';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as action from 'redux/actions';
import * as yup from 'yup';

const AudienceIndexPage = ({ data, dispatch, rowsPerPage, page, total, sdkVersions }) => {
  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    dispatch(action.fetchAudiences());
    dispatch(action.fetchSDKversions());
    return () => {};
  }, [dispatch]);

  const config = {
    category: 'system',
    columns: [
      {
        name: 'action',
        text: 'Actions',
        actions: [
          {
            name: 'edit',
            icon: Edit,
            onClick: ({ id }) => {
              dispatch(action.openEditAudienceModal(id));
            },
            title: 'Edit',
          },
          {
            name: 'delete',
            icon: Delete,
            onClick: (data) => {
              dispatch(action.openDeleteAudienceModal(data));
            },
            title: 'Delete',
          },
          {
            name: 'details',
            icon: MoreHoriz,
            path: '/system/audiences',
            title: 'Details',
          },
        ],
      },
      { name: 'name', text: 'Name', minWidth: 200 },
      { name: 'scope', text: 'Sdk Version' },
      { name: 'createdDate', text: 'Created Date' },
    ],
    modals: [
      {
        name: 'createAudience',
        fields: [
          {
            name: 'name',
            autoFocus: true,
            component: Single,
            label: 'Name',
            required: true,
            validation: yup.string().required('Name is required').min(2, 'Name should be of minimum 2 characters'),
          },
          {
            name: 'allowedOrigins',
            component: Multi,
            label: 'Allowed Origins',
            required: true,
            validation: yup.string().required('Allowed Origins is required'),
          },
          {
            component: Single,
            label: 'AEP Script Source',
            name: 'aepScriptSrc',
          },
          {
            component: Select,
            label: 'SDK Version',
            name: 'scope',
            required: true,
            options: sdkVersions,
            validation: yup.string().required('SDK Version is required'),
          },
        ],
        onSubmit: (data) => {
          dispatch(action.createAudience(data, { page, pageSize: rowsPerPage }));
        },
        title: 'Create',
      },
      {
        name: 'editAudience',
        fields: [
          {
            name: 'name',
            autoFocus: true,
            component: Single,
            label: 'Name',
            required: true,
            validation: yup.string().required('Name is required').min(2, 'Name should be of minimum 2 characters'),
          },
          {
            component: Multi,
            label: 'Allowed Origins',
            name: 'allowedOrigins',
            required: true,
            validation: yup.string().required('Allowed Origins is required'),
          },
          {
            component: Single,
            label: 'AEP Script Source',
            name: 'aepScriptSrc',
          },
          {
            component: Select,
            label: 'SDK Version',
            name: 'scope',
            required: true,
            options: sdkVersions,
            validation: yup.string().required('SDK Version is required'),
          },
        ],
        onSubmit: (data) => {
          dispatch(action.editAudience(data, 'index', { page, pageSize: rowsPerPage, name: searchValue }));
        },
        title: 'Edit',
      },
      {
        name: 'deleteAudience',
        contentText: 'Are you sure to delete the audience?',
        fields: [
          {
            name: 'name',
            component: Single,
            label: 'Name',
            readonly: true,
          },
        ],
        onSubmit: ({ id }) => {
          const newPage = data.length === 1 ? page - 1 : page;
          dispatch(action.deleteAudience(id, { page: newPage, pageSize: rowsPerPage }));
        },
        title: 'Delete',
      },
    ],
    title: 'Audiences',
    toolbarItems: [
      {
        name: 'search',
        icon: Search,
        onChange: (e) => {
          setSearchValue(e.target.value.trim());
        },
        onKeyUp: (e) => {
          e.keyCode === 13 &&
            searchValue.length >= 0 &&
            dispatch(action.fetchAudiences({ page: 0, pageSize: rowsPerPage, name: searchValue }));
        },
        text: 'Search',
        value: searchValue,
        type: 'text',
        clearValue: () => {
          setSearchValue('');
          dispatch(action.fetchAudiences());
        },
      },
      {
        name: 'refresh',
        icon: Refresh,
        onClick: () => {
          dispatch(action.fetchAudiences());
        },
        text: 'Refresh',
      },
      {
        name: 'create',
        icon: Add,
        onClick: () => {
          dispatch(
            action.openCreateAudienceModal({
              name: '',
              allowedOrigins: '',
              aepScriptSrc: '',
              sdkVersion: '',
            }),
          );
        },
        text: 'Create',
      },
    ],
  };

  return (
    <IndexPage
      config={config}
      data={data}
      setPage={(page) => dispatch(action.fetchAudiences({ page, pageSize: rowsPerPage, name: searchValue }))}
      setRowsPerPage={(rowsPerPage) =>
        dispatch(action.fetchAudiences({ page: 0, pageSize: rowsPerPage, name: searchValue }))
      }
      total={total}
      page={page}
      rowsPerPage={rowsPerPage}
    />
  );
};

const mapStateToProps = ({ audiences, sdkVersions }) => {
  return {
    /*  data: audiences.results.filter(
      (result) => result.name.toLowerCase().indexOf(audiences.searchText.toLowerCase()) > -1,
    ), */
    data: audiences.results,
    total: audiences.total,
    rowsPerPage: audiences.limit,
    page: audiences.page,
    sdkVersions: sdkVersions.results,
  };
};

export default connect(mapStateToProps)(AudienceIndexPage);
