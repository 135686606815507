import * as api from 'api';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Panel from './Panel';

const DynamicRequestBoard = ({ width, height, direction, border, shadow, filter, type, title, startDate, endDate }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const autoRefreshInterval = params.get('autoRefreshInterval') || 10000;
  const [requestAmount, setRequestAmount] = useState();

  useEffect(() => {
    api.fetchRequestAmount(startDate, endDate).then((res) => {
      setRequestAmount(res.data);
    });
    const intervalId = setInterval(() => {
      api.fetchRequestAmount(startDate, endDate).then((res) => {
        setRequestAmount(res.data);
      });
    }, autoRefreshInterval);
    return () => {
      clearInterval(intervalId);
    };
  }, [startDate, endDate]);

  return (
    <Panel
      width={width}
      height={height}
      border={border}
      shadow={shadow}
      title={title}
      amount={requestAmount}
      direction={direction}
      filter={filter}
      type={type}
    ></Panel>
  );
};

export default DynamicRequestBoard;
