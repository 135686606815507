import React from 'react';
import { TextField } from '@material-ui/core';

// eslint-disable-next-line react/display-name
export default (props) => {
  const { data, field, onChange, error, helperText, value, formik, onBlur } = props;

  const handleChange = (e) => {
    formik.handleChange(e);
    onChange(e.target.value);
  };

  return (
    <TextField
      autoFocus={field.autoFocus}
      fullWidth
      helperText={error ? helperText : field.helperText}
      id={field.name}
      InputLabelProps={field.placeholder ? { shrink: true } : null}
      inputProps={{ readOnly: field.readonly }}
      label={field.label}
      margin="normal"
      multiline
      name={field.name}
      onChange={handleChange}
      onBlur={onBlur}
      placeholder={field.placeholder}
      required={field.required}
      value={data[field.name] || value}
      variant={field.variant || 'standard'}
      error={error}
    />
  );
};
