import { Grid, Input, InputLabel, Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const SingleSlider = withStyles(() => ({
  root: {
    width: 250,
  },
  input: {
    width: 42,
  },
}))(({ classes, label, maxValue, minValue, onChange, stepValue, value }) => {
  const handleSliderChange = (event, newValue) => {
    onChange(newValue);
  };

  const handleInputChange = (e) => {
    if (e.target.value !== '') {
      const newValue = Number(e.target.value);
      onChange(newValue < minValue ? minValue : newValue > maxValue ? maxValue : newValue);
    }
  };

  return (
    <div className={classes.root}>
      <InputLabel shrink>{label}</InputLabel>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Slider
            aria-labelledby="input-slider"
            max={maxValue}
            min={minValue}
            onChange={handleSliderChange}
            step={stepValue}
            value={value}
          />
        </Grid>
        <Grid item>
          <Input
            className={classes.input}
            value={value}
            margin="dense"
            onChange={handleInputChange}
            inputProps={{
              step: stepValue,
              min: minValue,
              max: maxValue,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
});

export default ({ label, maxValue, minValue, stepValue, onChange, value, field }) => {
  const handleChange = (newValue) => {
    onChange(newValue);
  };

  return (
    <SingleSlider
      label={field ? field.label : label}
      maxValue={field ? field.maxValue : maxValue}
      minValue={field ? field.minValue : minValue}
      onChange={handleChange}
      step={field ? field.stepValue : stepValue}
      value={field ? field.value : value}
    ></SingleSlider>
  );
};
