export const hideLoading = () => {
  return { type: 'SET_LOADING', payload: false };
};

export const setLoading = (loading) => {
  return { type: 'SET_LOADING', payload: loading };
};

export const showLoading = () => {
  return { type: 'SET_LOADING', payload: true };
};
