import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  field: {
    margin: theme.spacing(0, 3),
  },
}));

export default (props) => {
  const classes = useStyles();
  const { data, fields } = props;

  return (
    <Grid container className={classes.root} spacing={2}>
      {fields &&
        fields.map(({ label, multiline, name, span }) => {
          return (
            <Grid item xs={span || 6} key={name}>
              <Box className={classes.field}>
                <TextField
                  fullWidth
                  id={name}
                  inputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  key={name}
                  label={label}
                  margin="normal"
                  multiline={multiline}
                  name={name}
                  value={data[name] || '-'}
                  disabled
                />
              </Box>
            </Grid>
          );
        })}
    </Grid>
  );
};
