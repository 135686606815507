import React, { useEffect } from 'react';
import DefaultLayout from 'layouts/DefaultLayout';

const NotificationIndexPage = () => {
  useEffect(() => {
    document.title = 'AR-Makeup Console';
  });
  return <DefaultLayout title="Notifications"></DefaultLayout>;
};

export default NotificationIndexPage;
