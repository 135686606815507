import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import SimpleList from 'components/SimpleList/SimpleList';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}));

export default function SimpleListTab(props) {
  const classes = useStyles();
  const { columns, data, ...rest } = props;

  return (
    <Grid container className={classes.root} spacing={0}>
      <Grid item xs={12}>
        <SimpleList columns={columns} data={data || []} {...rest} />
      </Grid>
    </Grid>
  );
}
