const initialState = {
  activeAccount: window.sessionStorage.getItem(`msal.${process.env.REACT_APP_CLIENT_ID}.active-account`),
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_LOGIN_INFO':
      return payload;
    default:
      return state;
  }
};
