const initialState = {
  total: 0,
  selectedShadesIndex: 0,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_SHADES': {
      return { ...state, ...payload };
    }
    case 'SET_SELECTED_SHADES_INDEX': {
      return { ...state, selectedShadesIndex: payload };
    }
    default:
      return state;
  }
};
