import React, { useEffect } from 'react';
import NavIndexPage from './../NavIndexPage';
import { SettingsApplications } from '@material-ui/icons';

const SystemIndexPage = () => {
  useEffect(() => {
    document.title = 'AR-Makeup Console';
  });
  const config = {
    category: 'system',
    title: 'System',
    items: [
      {
        name: 'Audiences',
        title: 'Audiences',
        description:
          'Audiences management page. where you can create new audience, search existing audience, display audience details, edit or delete audience, etc.',
        icon: SettingsApplications,
        link: '/system/audiences',
      },
    ],
  };
  return <NavIndexPage config={config}>System Index Page</NavIndexPage>;
};

export default SystemIndexPage;
