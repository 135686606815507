import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const Copyright = withStyles((theme) => ({
  root: {
    borderTop: '1px solid #ccc',
    padding: theme.spacing(1, 0),
    marginTop: 'auto',
    position: 'relative',
    top: theme.spacing(2),
  },
  text: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
    textAlign: 'center',
  },
}))(({ classes }) => {
  return (
    <div className={classes.root}>
      <div className={classes.text}>
        {`©${new Date().getFullYear()} `}
        {'AR-Makeup Console'}
      </div>
      <div className={classes.text}>
        {'powered by '}
        {'Henkel dx'}
      </div>
    </div>
  );
});

export default Copyright;
