import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import App from 'App';
import { msalConfig } from 'config';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'redux/store';
import reportWebVitals from 'reportWebVitals';
import './index.css';

const history = createBrowserHistory();

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
    store.dispatch({
      type: 'SET_LOGIN_INFO',
      payload: {
        activeAccount: window.sessionStorage.getItem(`msal.${process.env.REACT_APP_CLIENT_ID}.active-account`),
      },
    });
  }
});

msalInstance
  .handleRedirectPromise()
  .then((response) => {
    if (response) {
      const state = JSON.parse(response.state);
      if (state) {
        const { redirect } = state;
        if (redirect) {
          const redirectUrl = `${redirect.pathname}${redirect.search}`;
          history.push(redirectUrl);
        }
      }
    }
  })
  .catch((error) => console.log(error));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <App history={history} />
      </MsalProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
