import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Link, Typography, Breadcrumbs } from '@material-ui/core';

import { Link as RouterLink, useLocation } from 'react-router-dom';

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;
const Breadcrumb = withStyles(() => ({
  link: {
    textTransform: 'capitalize',
  },
}))(({ classes, data, isDetails }) => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <LinkRouter color="inherit" underline="hover" to="/">
        Home
      </LinkRouter>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        return last ? (
          <Typography color="inherit" key={to} className={classes.link}>
            {isDetails ? (data.name ? data.name : data.shadeName) : value}
          </Typography>
        ) : (
          <LinkRouter underline="hover" color="inherit" className={classes.link} to={to} key={to}>
            {value}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
});

export default Breadcrumb;
