import axios from 'axios';
import { axiosConfig } from 'config';
import { loginRequest } from '../config/index';
import { msalInstance } from '../index';

export const apiOptions = async () => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account,
  });

  const bearer = `Bearer ${response.accessToken}`;

  const options = {
    ...axiosConfig,
    headers: {
      'Authorization': bearer,
    },
  };

  return options;
};

export const del = async (url) => {
  const options = await apiOptions();

  return axios.delete(url, options).catch(({ message, response }) => {
    if (response) {
      if (response.data && response.data.error_description) {
        return Promise.reject(response.data.error_description);
      } else {
        return Promise.reject(response.statusText);
      }
    } else {
      return Promise.reject(message);
    }
  });
};

export const get = async (url) => {
  const options = await apiOptions();

  return axios.get(url, options).catch(({ message, response }) => {
    if (response) {
      if (response.data && response.data.error_description) {
        return Promise.reject(response.data.error_description);
      } else {
        return Promise.reject(response.statusText);
      }
    } else {
      return Promise.reject(message);
    }
  });
};

export const post = async (url, data) => {
  const options = await apiOptions();

  return axios.post(url, data, options).catch(({ message, response }) => {
    if (response) {
      if (response.data && response.data.error_description) {
        return Promise.reject(response.data.error_description);
      } else {
        return Promise.reject(response.statusText);
      }
    } else {
      return Promise.reject(message);
    }
  });
};

export const put = async (url, data) => {
  const options = await apiOptions();

  return axios.put(url, data, options).catch(({ message, response }) => {
    if (response) {
      if (response.data && response.data.error_description) {
        return Promise.reject(response.data.error_description);
      } else {
        return Promise.reject(response.statusText);
      }
    } else {
      return Promise.reject(message);
    }
  });
};

export * from 'api/audience';
export * from 'api/sdkVersion';
export * from 'api/style';
export * from 'api/dashboard';
export * from 'api/product';
