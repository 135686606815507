import { Add, Delete, Edit, MoreHoriz, Refresh, Search } from '@material-ui/icons';
import Single from 'components/Form/Single';
// import Select from 'components/Form/Select';
import Multi from 'components/Form/Multi';
import IndexPage from 'pages/IndexPage';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as action from 'redux/actions';
import LocalDate from 'components/Form/LocalDate';
import * as yup from 'yup';

const EyecolorIndexPage = ({ data, dispatch, rowsPerPage, page, total }) => {
  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    dispatch(action.fetchProducts({ page, pageSize: rowsPerPage, part: 'eyecolor' }));
    return () => {};
  }, [dispatch]);

  const config = {
    category: 'products',
    categoryHierarchy: ['masterdata', 'products', 'makeup', 'eye', 'eyecolor'],
    columns: [
      {
        name: 'action',
        text: 'Actions',
        actions: [
          {
            name: 'edit',
            icon: Edit,
            onClick: ({ id }) => {
              dispatch(action.openEditProductModal(id));
            },
            title: 'Edit',
          },
          {
            name: 'delete',
            icon: Delete,
            onClick: (data) => {
              dispatch(action.openDeleteProductModal(data));
            },
            title: 'Delete',
          },
          {
            name: 'details',
            icon: MoreHoriz,
            path: '/eyecolor',
            title: 'Details',
          },
        ],
      },
      {
        name: 'name',
        text: 'Name',
      },
      {
        name: 'createdDate',
        component: LocalDate,
        text: 'Created Date',
      },
    ],
    modals: [
      {
        name: 'createProduct',
        fields: [
          {
            name: 'part',
            component: Single,
            label: 'Part',
            required: true,
            readonly: true,
            validation: yup.string().required('Part is required'),
          },
          {
            name: 'name',
            autoFocus: true,
            component: Single,
            label: 'Name',
            required: true,
            validation: yup.string().required('Name is required'),
          },
          {
            name: 'allowedAudiences',
            validation: yup.string().required('Allowed Audiences are required'),
            component: Multi,
            label: 'Allowed Audiences',
            required: true,
          },
          {
            name: 'allowedStyles',
            validation: yup.string().required('Allowed Styles are required'),
            component: Multi,
            label: 'Allowed Styles',
            required: true,
          },
          {
            name: 'version',
            component: Single,
            label: 'Version',
          },
        ],
        onSubmit: (data) => {
          dispatch(action.createProduct(data, { page, pageSize: rowsPerPage, part: 'eyecolor' }));
        },
        title: 'Create',
      },
      {
        name: 'editProduct',
        fields: [
          {
            name: 'part',
            validation: yup.string().required('Part is required'),
            component: Single,
            label: 'Part',
            required: true,
            readonly: true,
          },
          {
            name: 'name',
            autoFocus: true,
            component: Single,
            label: 'Name',
            required: true,
          },
          {
            name: 'allowedAudiences',
            autoFocus: true,
            component: Multi,
            label: 'Allowed Audiences',
            required: true,
          },
          {
            name: 'allowedStyles',
            autoFocus: true,
            component: Multi,
            label: 'Allowed Styles',
            required: true,
          },
          {
            name: 'version',
            component: Single,
            label: 'Version',
          },
        ],
        onSubmit: (data) => {
          dispatch(action.editProduct(data, 'index', { page, pageSize: rowsPerPage, part: 'eyecolor' }));
        },
        title: 'Edit eyecolor',
      },
      {
        name: 'deleteProduct',
        contentText: 'Are you sure to delete the product?',
        fields: [
          {
            name: 'name',
            component: Single,
            label: 'Name',
            readonly: true,
          },
        ],
        onSubmit: ({ id }) => {
          dispatch(action.deleteProduct(id, { page, pageSize: rowsPerPage, part: 'eyecolor' }));
        },
        title: 'Delete eyecolor',
      },
    ],
    title: 'Eyecolor',
    toolbarItems: [
      {
        name: 'search',
        icon: Search,
        onChange: (e) => {
          setSearchValue(e.target.value.trim());
        },
        onKeyUp: (e) => {
          e.keyCode === 13 &&
            searchValue.length >= 0 &&
            dispatch(action.fetchProducts({ page, pageSize: rowsPerPage, part: 'eyecolor', name: searchValue }));
        },
        text: 'Search',
        value: searchValue,
        type: 'text',
        clearValue: () => {
          setSearchValue('');
          dispatch(action.fetchProducts({ page, pageSize: rowsPerPage, part: 'eyecolor' }));
        },
      },
      {
        name: 'refresh',
        icon: Refresh,
        onClick: () => {
          dispatch(action.fetchProducts({ page, pageSize: rowsPerPage, part: 'eyecolor' }));
        },
        text: 'Refresh',
      },
      {
        name: 'create',
        icon: Add,
        onClick: () => {
          dispatch(
            action.openCreateProductModal({
              part: 'eyecolor',
              name: '',
              allowedAudiences: '',
              allowedStyles: '',
              version: '',
            }),
          );
        },
        text: 'Create',
      },
    ],
  };

  return (
    <IndexPage
      config={config}
      data={data}
      setPage={(page) =>
        dispatch(action.fetchProducts({ page, pageSize: rowsPerPage, part: 'eyecolor', name: searchValue }))
      }
      setRowsPerPage={(rowsPerPage) =>
        dispatch(action.fetchProducts({ page: 0, pageSize: rowsPerPage, part: 'eyecolor', name: searchValue }))
      }
      total={total}
      page={page}
      rowsPerPage={rowsPerPage}
    />
  );
};

const mapStateToProps = ({ products }) => ({
  data: products.results,
  total: products.total,
  rowsPerPage: products.limit,
  page: products.page,
});

export default connect(mapStateToProps)(EyecolorIndexPage);
