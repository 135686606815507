import React from 'react';
import { connect } from 'react-redux';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select as MuiSelect } from '@material-ui/core';

const Select = ({
  data,
  field: { label, name, required, variant, minWidth },
  helperText,
  onChange,
  options,
  formik,
  error,
}) => {
  const handleChange = (e) => {
    formik.handleChange(e);
    onChange(e.target.value);
  };

  return (
    <FormControl
      style={{ width: `${minWidth}px` }}
      fullWidth={minWidth ? false : true}
      margin="normal"
      required={required}
      variant={variant || 'standard'}
      error={error}
    >
      <InputLabel htmlFor={`select-${name}`} required={required}>
        {label}
      </InputLabel>
      <MuiSelect
        inputProps={{ id: `select-${name}` }}
        label={`${label}${required && ' *'}`}
        // helperText={error ? helperText : helperText}
        name={name}
        onChange={handleChange}
        value={data[name]}
      >
        {options.map((option) => {
          return (
            <MenuItem value={option.value} key={option.value}>
              {option.text}
            </MenuItem>
          );
        })}
      </MuiSelect>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

const mapStateToProps = (state, { field: { lookup, options = [] } }) => ({
  options: lookup ? state[lookup] : options,
});

export default connect(mapStateToProps)(Select);
