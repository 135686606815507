import * as api from 'api/product';
import _ from 'lodash';
import * as action from 'redux/actions';

//product
export const createProduct = (data, pageParams) => (dispatch) => {
  dispatch(action.closeModal());
  dispatch(action.showLoading());
  api
    .createProduct(data)
    .then(() => {
      api.getProducts(pageParams).then((response) => {
        dispatch(action.setProducts(response.data));
      });
    })
    .then(() => {
      dispatch(action.setSuccess('The project has been created.'));
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const deleteProduct = (id, pageParams, resolve) => (dispatch) => {
  dispatch(action.closeModal());
  dispatch(action.showLoading());
  api.deleteProduct(id).then(() => {
    if (resolve) {
      return resolve();
    }
  });
  return api
    .getProducts(pageParams)
    .then((response) => {
      dispatch(action.setStylesPage(pageParams.page));
      dispatch(action.setProducts(response.data));
    })
    .then(() => {
      dispatch(action.setSuccess('The product has been deleted'));
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const editProduct =
  (data, context = null, pageParams) =>
  (dispatch) => {
    dispatch(action.closeModal());
    dispatch(action.showLoading());
    api
      .editProduct(data)
      .then(() => {
        if (context === 'details') {
          return api.getProduct(data.id).then((response) => {
            const allowedStyles = Array.isArray(response.data.allowedStyles)
              ? _.join(response.data.allowedStyles, '\n')
              : response.data.allowedStyles;
            const allowedAudiences = Array.isArray(response.data.allowedAudiences)
              ? _.join(response.data.allowedAudiences, '\n')
              : response.data.allowedAudiences;
            const responseData = { ...response.data, allowedStyles, allowedAudiences };
            dispatch(action.setProduct(responseData));
          });
        } else {
          return api.getProducts(pageParams).then((response) => {
            dispatch(action.setProducts(response.data));
          });
        }
      })
      .then(() => {
        dispatch(action.setSuccess('The product has been updated.'));
      })
      .catch((error) => {
        dispatch(action.setError(error));
      })
      .finally(() => {
        dispatch(action.hideLoading());
      });
  };

//shade
export const editProductShade =
  (data, context = null) =>
  (dispatch) => {
    dispatch(action.closeModal());
    dispatch(action.showLoading());

    api
      .editProductShade(data)
      .then(() => {
        if (context === 'details') {
          return api.getProduct(data.id);
        } else {
          return api.getProducts();
        }
      })
      .then((response) => {
        if (context === 'details') {
          dispatch(action.setProduct(response.data));
        } else {
          dispatch(action.setProducts(response.data));
        }
      })
      .then(() => {
        dispatch(action.setSuccess('Shades updated successfully.'));
      })
      .catch((error) => {
        dispatch(action.setError(error));
      })
      .finally(() => {
        dispatch(action.hideLoading());
      });
  };

//product fetch
export const fetchProduct = (id) => (dispatch) => {
  dispatch(action.showLoading());
  api
    .getProduct(id)
    .then((response) => {
      dispatch(action.setProduct(response.data));
      dispatch(action.setShades({ total: response.data.shades.length }));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const fetchProducts = (pageParams) => (dispatch) => {
  dispatch(action.showLoading());
  api
    .getProducts(pageParams)
    .then((response) => {
      const page = Math.ceil(response.data.offset / response.data.limit);
      dispatch(action.setProducts(response.data));
      dispatch(action.setProductsPage(page));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

//open modal
export const openCreateProductModal = (data) => (dispatch) => {
  dispatch(action.setFormData(data));
  dispatch(action.openModal('createProduct'));
};

export const openEditProductModal = (id) => (dispatch) => {
  dispatch(action.showLoading());
  api
    .getProduct(id)
    .then((response) => {
      const allowedStyles = Array.isArray(response.data.allowedStyles)
        ? _.join(response.data.allowedStyles, '\n')
        : response.data.allowedStyles;
      const allowedAudiences = Array.isArray(response.data.allowedAudiences)
        ? _.join(response.data.allowedAudiences, '\n')
        : response.data.allowedAudiences;
      const responseData = { ...response.data, allowedStyles, allowedAudiences };
      dispatch(action.setFormData(responseData));
      dispatch(action.openModal('editProduct'));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const openDeleteProductModal = (data) => (dispatch) => {
  dispatch(action.setFormData(data));
  dispatch(action.openModal('deleteProduct'));
};

export const openReleaseProductModal = (id) => (dispatch) => {
  dispatch(action.showLoading());
  api
    .getProduct(id)
    .then((response) => {
      dispatch(action.setFormData(response.data));
      dispatch(action.openModal('releaseProduct'));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const openEditProductShadeModal = (id) => {
  const productId = id.split('#')[0];
  const index = id.split('#')[1];

  return (dispatch) => {
    dispatch(action.showLoading());
    api
      .getProductShades(productId)
      .then((response) => {
        dispatch(action.setFormData(response.data.shades[index]));
        // dispatch(action.setSelectedShadesIndex(index));
        dispatch(action.openModal('editProductShade'));
        return Promise.resolve();
      })
      .catch((error) => {
        dispatch(action.setError(error));
      })
      .finally(() => {
        dispatch(action.hideLoading());
      });
  };
};

//action
export const resetProduct = () => ({ type: 'SET_PRODUCT', payload: {} });

export const setProduct = (product) => ({
  type: 'SET_PRODUCT',
  payload: product,
});

export const setProducts = (products) => {
  return {
    type: 'SET_PRODUCTS',
    payload: products,
  };
};

export const setProductsPage = (page) => ({
  type: 'SET_PRODUCTS_PAGE',
  payload: page,
});
