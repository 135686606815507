import React from 'react';
import PropTypes from 'prop-types';

const FormField = (props) => {
  const { component: Component, ...rest } = props;

  return <Component {...rest} />;
};

FormField.propTypes = {
  data: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  formik: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.any,
};

export default FormField;
