import { Add, Delete, Edit, MoreHoriz, Refresh, Search } from '@material-ui/icons';
import Single from 'components/Form/Single';
import Multi from 'components/Form/Multi';
import IndexPage from 'pages/IndexPage';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as action from 'redux/actions';
import * as yup from 'yup';

const StyleIndexPage = ({ data, dispatch, rowsPerPage, page, total }) => {
  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    document.title = 'AR-Makeup Console';
    dispatch(action.fetchStyles());
    return () => {};
  }, [dispatch]);

  const config = {
    category: 'masterdata',
    columns: [
      {
        name: 'action',
        text: 'Actions',
        actions: [
          {
            name: 'edit',
            icon: Edit,
            onClick: ({ id }) => {
              dispatch(action.openEditStyleModal(id));
            },
            title: 'Edit',
          },
          {
            name: 'delete',
            icon: Delete,
            onClick: (data) => {
              dispatch(action.openDeleteStyleModal(data));
            },
            title: 'Delete',
          },
          {
            name: 'details',
            icon: MoreHoriz,
            path: '/master data/styles',
            title: 'Details',
          },
        ],
      },
      { name: 'part', text: 'Part' },
      { name: 'name', text: 'Name' },
    ],
    modals: [
      {
        name: 'createStyle',
        fields: [
          {
            name: 'part',
            autoFocus: true,
            component: Single,
            label: 'Part',
            required: true,
            validation: yup.string().required('Part is required'),
          },
          {
            name: 'name',
            component: Single,
            label: 'Name',
            required: true,
            validation: yup.string().required('Name is required').min(2, 'Name should be of minimum 2 characters'),
          },
          {
            name: 'config',
            component: Multi,
            required: true,
            label: 'Config',
            validation: yup.string().required('config is required'),
          },
        ],

        onSubmit: (data) => {
          dispatch(action.createStyle(data, { page, pageSize: rowsPerPage }));
        },
        title: 'Create',
      },
      {
        name: 'editStyle',
        fields: [
          {
            name: 'part',
            autoFocus: true,
            component: Single,
            label: 'Part',
            required: true,
            validation: yup.string().required('Part is required'),
          },
          {
            name: 'name',
            autoFocus: true,
            component: Single,
            label: 'Name',
            required: true,
            validation: yup.string().required('Name is required').min(2, 'Name should be of minimum 2 characters'),
          },
          {
            name: 'config',
            component: Multi,
            required: true,
            label: 'Config',
            validation: yup.string().required('config is required'),
          },
        ],
        onSubmit: (data) => {
          dispatch(action.editStyle(data, 'index', { page, pageSize: rowsPerPage, part: searchValue }));
        },
        title: 'Edit',
      },
      {
        name: 'deleteStyle',
        contentText: 'Are you sure to delete the style?',
        fields: [
          {
            name: 'part',
            component: Single,
            label: 'Part',
            readonly: true,
          },
          {
            name: 'name',
            component: Single,
            label: 'Name',
            readonly: true,
          },
        ],
        onSubmit: ({ id }) => {
          const newPage = data.length === 1 ? page - 1 : page;
          dispatch(action.deleteStyle(id, { page: newPage, pageSize: rowsPerPage }));
        },
        title: 'Delete',
      },
    ],
    title: 'Styles',
    toolbarItems: [
      {
        name: 'search',
        icon: Search,
        onChange: (e) => {
          setSearchValue(e.target.value.trim());
        },
        onKeyUp: (e) => {
          e.keyCode === 13 &&
            searchValue.length >= 0 &&
            dispatch(action.fetchStyles({ page: 0, pageSize: rowsPerPage, part: searchValue }));
        },
        text: 'Search',
        value: searchValue,
        type: 'text',
        clearValue: () => {
          setSearchValue('');
          dispatch(action.fetchStyles());
        },
      },
      {
        name: 'refresh',
        icon: Refresh,
        onClick: () => {
          dispatch(action.fetchStyles());
        },
        text: 'Refresh',
      },
      {
        name: 'create',
        icon: Add,
        onClick: () => {
          dispatch(
            action.openCreateStyleModal({
              name: '',
              part: '',
              config: '',
            }),
          );
        },
        text: 'Create',
      },
    ],
  };

  return (
    <IndexPage
      config={config}
      data={data}
      setPage={(page) => dispatch(action.fetchStyles({ page, pageSize: rowsPerPage, part: searchValue }))}
      setRowsPerPage={(rowsPerPage) =>
        dispatch(action.fetchStyles({ page: 0, pageSize: rowsPerPage, part: searchValue }))
      }
      total={total}
      page={page}
      rowsPerPage={rowsPerPage}
    />
  );
};

const mapStateToProps = ({ styles }) => {
  return {
    data: styles.results.filter((result) => result.name.toLowerCase().indexOf(styles.searchText.toLowerCase()) > -1),
    total: styles.total,
    rowsPerPage: styles.limit,
    page: styles.page,
  };
};

export default connect(mapStateToProps)(StyleIndexPage);
