import * as api from 'api';
import * as action from 'redux/actions';

export const createStyle = (data, pageParams) => (dispatch) => {
  dispatch(action.closeModal());
  dispatch(action.showLoading());
  api
    .createStyle(data)
    .then(() => {
      api.getStyles(pageParams).then((response) => {
        dispatch(action.setStyles(response.data));
      });
    })
    .then(() => {
      dispatch(action.setSuccess('The style has been created.'));
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const deleteStyle = (id, pageParams, resolve) => (dispatch) => {
  dispatch(action.closeModal());
  dispatch(action.showLoading());

  api
    .deleteStyle(id)
    .then(() => {
      if (resolve) {
        return resolve();
      }
      return api.getStyles(pageParams).then((response) => {
        dispatch(action.setStylesPage(pageParams.page));
        dispatch(action.setStyles(response.data));
      });
    })
    .then(() => {
      dispatch(action.setSuccess('The style has been deleted.'));
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const editStyle =
  (data, context = null, pageParams) =>
  (dispatch) => {
    dispatch(action.closeModal());
    dispatch(action.showLoading());

    api
      .editStyle(data)
      .then(() => {
        if (context === 'details') {
          return api.getStyle(data.id).then((response) => {
            response.data.config = JSON.stringify(response.data.config);
            dispatch(action.setStyle(response.data));
          });
        } else {
          return api.getStyles(pageParams).then((response) => {
            dispatch(action.setStyles(response.data));
          });
        }
      })
      .then(() => {
        dispatch(action.setSuccess('The style has been updated.'));
      })
      .catch((error) => {
        dispatch(action.setError(error));
      })
      .finally(() => {
        dispatch(action.hideLoading());
      });
  };

export const fetchStyle = (id) => (dispatch) => {
  dispatch(action.showLoading());
  api
    .getStyle(id)
    .then((response) => {
      response.data.config = JSON.stringify(response.data.config);
      dispatch(action.setStyle(response.data));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const fetchStyles = (pageParams) => (dispatch) => {
  dispatch(action.showLoading());
  api
    .getStyles(pageParams)
    .then((response) => {
      const page = Math.ceil(response.data.offset / response.data.limit);
      dispatch(action.setStyles(response.data));
      dispatch(action.setStylesPage(page));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const openCreateStyleModal = (data) => (dispatch) => {
  dispatch(action.setFormData(data));
  dispatch(action.openModal('createStyle'));
};

export const openEditStyleModal = (id) => (dispatch) => {
  dispatch(action.showLoading());
  api
    .getStyle(id)
    .then((response) => {
      response.data.config = JSON.stringify(response.data.config);
      dispatch(action.setFormData(response.data));
      dispatch(action.openModal('editStyle'));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const openDeleteStyleModal = (data) => (dispatch) => {
  dispatch(action.setFormData(data));
  dispatch(action.openModal('deleteStyle'));
};

export const filterStyle = (keyword) => (dispatch) => {
  dispatch(action.setStyleFilter(keyword));
};

export const resetStyle = () => ({ type: 'SET_STYLES', payload: {} });

export const setStyle = (style) => ({
  type: 'SET_STYLE',
  payload: style,
});

export const setStyles = (styles) => {
  return {
    type: 'SET_STYLES',
    payload: styles,
  };
};

export const setStyleFilter = (keyword) => ({
  type: 'SET_STYLES_FILTER',
  payload: keyword,
});

export const setStylesPage = (page) => ({
  type: 'SET_STYLES_PAGE',
  payload: page,
});
