const initDate = null;

export default (state = initDate, { type, payload }) => {
  switch (type) {
    case 'SET_OPENING_MODAL':
      return payload;
    default:
      return state;
  }
};
