import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';

const Panel = withStyles(() => ({
  chartPanel: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFF',
    margin: '40px',
    padding: '10px',
    borderRadius: '5px',
  },
  panelTitle: {
    // paddingRight: '60%',
    margin: '20px 0',
    textAlign: 'left',
    fontSize: '20px',
  },
  panelAmount: {
    textAlign: 'center',
    color: 'gray',
    fontSize: '50px',
  },
}))(({ classes, height = '200', direction = 'row', title = '', amount = 0, children: Children }) => {
  const [prevAmount, setPrevAmount] = useState();
  let propChanges = { oldValue: 0, newValue: amount };

  useEffect(() => {
    if (prevAmount) {
      if (amount !== prevAmount) {
        propChanges = {
          oldValue: prevAmount,
          newValue: amount,
        };
      }
    }
    setPrevAmount(amount);
  }, [amount]);

  return (
    <div className={classnames(classes.chartPanel)} style={{ height: `${height}`, flexDirection: `${direction}` }}>
      {Children ? (
        Children
      ) : (
        <>
          {title ? <h3 className={classes.panelTitle}>{title}</h3> : null}
          {amount === 0 ? (
            <h1 className={classes.panelAmount}>{amount}</h1>
          ) : (
            <CountUp
              start={propChanges.oldValue || 0}
              end={propChanges.newValue}
              duration={1}
              separator=","
              delay={0}
              preserveValue
            >
              {({ countUpRef }) => <h1 className={classes.panelAmount} ref={countUpRef}></h1>}
            </CountUp>
          )}
        </>
      )}
    </div>
  );
});

export default Panel;
