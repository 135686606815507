import React, { Fragment } from 'react';

export default (props) => {
  const { data, field } = props;

  return (
    <Fragment>
      {data[field.name] === ''
        ? '-'
        : new Date(data[field.name]).toLocaleDateString() + '  ' + new Date(data[field.name]).toLocaleTimeString()}
    </Fragment>
  );
};
