import { Add, Delete, Edit, MoreHoriz, Refresh, Search } from '@material-ui/icons';
import LocalDate from 'components/Form/LocalDate';
import Select from 'components/Form/Select';
import Single from 'components/Form/Single';
import Multi from 'components/Form/Multi';
import IndexPage from 'pages/IndexPage';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as action from 'redux/actions';
import * as yup from 'yup';

const StamplinerIndexPage = ({ data, dispatch, rowsPerPage, page, total }) => {
  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    dispatch(action.fetchProducts({ page, pageSize: rowsPerPage, part: 'stampliner' }));
    return () => {};
  }, [dispatch]);

  const config = {
    category: 'products',
    categoryHierarchy: ['masterdata', 'products', 'makeup', 'eye', 'stampliner'],
    columns: [
      {
        name: 'action',
        text: 'Actions',
        actions: [
          {
            name: 'edit',
            icon: Edit,
            onClick: ({ id }) => {
              dispatch(action.openEditProductModal(id));
            },
            title: 'Edit',
          },
          {
            name: 'delete',
            icon: Delete,
            onClick: (data) => {
              dispatch(action.openDeleteProductModal(data));
            },
            title: 'Delete',
          },
          {
            name: 'details',
            icon: MoreHoriz,
            path: '/stampliner',
            title: 'Details',
          },
        ],
      },
      {
        name: 'name',
        text: 'Name',
      },
      {
        name: 'range',
        text: 'Range',
      },
      {
        name: 'finish',
        text: 'Finish',
      },
      {
        name: 'shadeName',
        text: 'ShadeName',
      },
      {
        name: 'createdDate',
        component: LocalDate,
        text: 'Created Date',
      },
    ],
    modals: [
      {
        name: 'createProduct',
        fields: [
          {
            name: 'part',
            component: Single,
            label: 'Part',
            required: true,
            readonly: true,
            validation: yup.string().required('Part is required'),
          },
          {
            name: 'name',
            autoFocus: true,
            component: Single,
            label: 'Name',
            required: true,
            validation: yup.string().required('Name is required'),
          },
          {
            name: 'range',
            validation: yup.string().required('Range is required'),
            component: Single,
            label: 'Range',
            required: true,
          },
          {
            name: 'shadeName',
            validation: yup.string().required('Shade Name is required'),
            component: Single,
            label: 'ShadeName',
            required: true,
          },
          {
            name: 'texture',
            component: Select,
            label: 'Texture',
            options: [
              { value: '--', text: ' --' },
              { value: 'Liquid', text: 'Liquid' },
              { value: 'Cream', text: 'Cream' },
              { value: 'Powder', text: 'Powder' },
            ],
            required: true,
            validation: yup.string().required('Texture is required'),
          },
          {
            name: 'finish',
            component: Select,
            label: 'Finish',
            options: [
              { value: '--', text: ' --' },
              { value: 'Matte', text: 'Matte' },
              { value: 'Glossy', text: 'Glossy' },
              { value: 'Glitter', text: 'Glitter' },
              { value: 'Shiny', text: 'Shiny' },
            ],
            required: true,
            validation: yup.string().required('Finish is required'),
          },
          {
            name: 'allowedAudiences',
            validation: yup.string().required('Allowed Audiences are required'),
            component: Multi,
            label: 'Allowed Audiences',
            required: true,
          },
          {
            name: 'allowedStyles',
            validation: yup.string().required('Allowed Styles are required'),
            component: Multi,
            label: 'Allowed Styles',
            required: true,
          },
          {
            name: 'version',
            component: Single,
            label: 'Version',
          },
        ],
        onSubmit: (data) => {
          dispatch(action.createProduct(data, { page, pageSize: rowsPerPage, part: 'stampliner' }));
        },
        title: 'Create',
      },
      {
        name: 'editProduct',
        fields: [
          {
            name: 'part',
            autoFocus: true,
            component: Single,
            label: 'Part',
            required: true,
            readonly: true,
          },
          {
            name: 'name',
            autoFocus: true,
            component: Single,
            label: 'Name',
            required: true,
          },
          {
            name: 'range',
            autoFocus: true,
            component: Single,
            label: 'Range',
            required: true,
          },
          {
            name: 'shadeName',
            autoFocus: true,
            component: Single,
            label: 'ShadeName',
            required: true,
          },
          {
            name: 'texture',
            component: Select,
            label: 'Texture',
            options: [
              { value: '--', text: ' --' },
              { value: 'Liquid', text: 'Liquid' },
              { value: 'Cream', text: 'Cream' },
              { value: 'Powder', text: 'Powder' },
            ],
            required: true,
          },
          {
            name: 'finish',
            component: Select,
            label: 'Finish',
            options: [
              { value: '--', text: ' --' },
              { value: 'Matte', text: 'Matte' },
              { value: 'Glossy', text: 'Glossy' },
              { value: 'Glitter', text: 'Glitter' },
              { value: 'Shiny', text: 'Shiny' },
            ],
            required: true,
          },
          {
            name: 'allowedAudiences',
            autoFocus: true,
            component: Multi,
            label: 'Allowed Audiences',
            required: true,
          },
          {
            name: 'allowedStyles',
            autoFocus: true,
            component: Multi,
            label: 'Allowed Styles',
            required: true,
          },
          {
            name: 'version',
            component: Single,
            label: 'Version',
          },
        ],
        onSubmit: (data) => {
          dispatch(action.editProduct(data, 'index', { page, pageSize: rowsPerPage, part: 'stampliner' }));
        },
        title: 'Edit Stampliner',
      },
      {
        name: 'deleteProduct',
        contentText: 'Are you sure to delete the product?',
        fields: [
          {
            name: 'name',
            component: Single,
            label: 'Name',
            readonly: true,
          },
        ],
        onSubmit: ({ id }) => {
          dispatch(action.deleteProduct(id, { page, pageSize: rowsPerPage, part: 'stampliner' }));
        },
        title: 'Delete Stampliner',
      },
    ],
    title: 'Stampliner',
    toolbarItems: [
      {
        name: 'search',
        icon: Search,
        onChange: (e) => {
          setSearchValue(e.target.value.trim());
        },
        onKeyUp: (e) => {
          e.keyCode === 13 &&
            searchValue.length >= 0 &&
            dispatch(action.fetchProducts({ page, pageSize: rowsPerPage, part: 'stampliner', name: searchValue }));
        },
        text: 'Search',
        value: searchValue,
        type: 'text',
        clearValue: () => {
          setSearchValue('');
          dispatch(action.fetchProducts({ page, pageSize: rowsPerPage, part: 'stampliner' }));
        },
      },
      {
        name: 'refresh',
        icon: Refresh,
        onClick: () => {
          dispatch(action.fetchProducts({ page, pageSize: rowsPerPage, part: 'stampliner' }));
        },
        text: 'Refresh',
      },
      {
        name: 'create',
        icon: Add,
        onClick: () => {
          dispatch(
            action.openCreateProductModal({
              part: 'stampliner',
              name: '',
              range: '',
              shadeName: '',
              texture: '',
              finish: '',
              allowedAudiences: '',
              allowedStyles: '',
              version: '',
            }),
          );
        },
        text: 'Create',
      },
    ],
  };

  return (
    <IndexPage
      config={config}
      data={data}
      setPage={(page) =>
        dispatch(action.fetchProducts({ page, pageSize: rowsPerPage, part: 'stampliner', name: searchValue }))
      }
      setRowsPerPage={(rowsPerPage) =>
        dispatch(action.fetchProducts({ page: 0, pageSize: rowsPerPage, part: 'stampliner', name: searchValue }))
      }
      total={total}
      page={page}
      rowsPerPage={rowsPerPage}
    />
  );
};

const mapStateToProps = ({ products }) => ({
  // data: _.filter(products, { part: 'stampliner' }),
  data: products.results,
  total: products.total,
  rowsPerPage: products.limit,
  page: products.page,
});

export default connect(mapStateToProps)(StamplinerIndexPage);
