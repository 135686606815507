import * as api from 'api';
import * as action from 'redux/actions';
export const fetchSDKversions = () => (dispatch) => {
  api
    .getSDKVersion()
    .then((response) => {
      const responseDataResults = response.data.map((item) => {
        return {
          value: item.name,
          text: item.name,
        };
      });

      dispatch(action.setSDKVersions({ ...response, results: responseDataResults }));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const setSDKVersions = (sdkVersions) => {
  return {
    type: 'SET_SDKVERSIONS',
    payload: sdkVersions,
  };
};
