import { Backdrop as MuiBackdrop, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { navigationDrawerWidth } from 'config';
import React from 'react';
import { connect } from 'react-redux';

const Backdrop = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
})(MuiBackdrop);

const Loading = withStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    marginLeft: 0,
    marginTop: theme.spacing(8),
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  backdropShift: {
    marginLeft: navigationDrawerWidth,
  },
}))(({ classes, drawerOpen, loading }) => (
  <Backdrop
    className={classnames(classes.backdrop, {
      [classes.backdropShift]: drawerOpen,
    })}
    open={loading}
  >
    <CircularProgress color="primary" />
  </Backdrop>
));

export default connect(({ loading }) => ({ loading }))(Loading);
