import { del, get, post, put } from 'api';
import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

// let mockupProducts = [
//   {
//     'guid': 'FA7C7476-8D60-476A-AD81-45EBDB6FB0BD',
//     'part': 'blush',
//     'range': 'CHEEKY BLOOM - Blush Powder',
//     'shadeName': 'Mademoiselle',
//     'product': 'got2b Powder Blush Light Pink',
//     'texture': 'Powder',
//     'finish': 'Matte',
//     'shades': [
//       {
//         'color': [17, 54, 15],
//         'strength': 20,
//       },
//     ],
//     'version': '1',
//     'releaseDate': '',
//   },
//   {
//     'guid': 'FA7C7476-8D60-476A-AD81-46EBDB6FB0BD',
//     'part': 'blush',
//     'range': 'CHEEKY BLOOM - Blush Powder',
//     'shadeName': 'Mademoiselle',
//     'product': 'got2b Powder Blush Light Pink',
//     'texture': 'Powder',
//     'finish': 'Matte',
//     'shades': [
//       {
//         'color': [217, 154, 155],
//         'strength': 100,
//       },
//     ],
//     'version': '1',
//     'releaseDate': '',
//   },
//   {
//     // 'id': '0E80D127-10DA-4B77-878B-DC6ACFFC99A9',
//     'guid': '0E80D127-10DA-4B77-878B-DC6ACFFC99A9',
//     'part': 'lipliner',
//     'range': 'aa',
//     'shadeName': 'bb',
//     'product': 'got2b Powder aa',
//     'texture': 'Powder',
//     'finish': 'Matte',
//     'shades': [
//       {
//         'color': [98, 67, 35],
//         'strength': 30,
//       },
//       {
//         'color': [100, 27, 125],
//         'strength': 40,
//       },
//       {
//         'color': [78, 67, 235],
//         'strength': 50,
//       },
//       {
//         'color': [48, 67, 75],
//         'strength': 60,
//       },
//       {
//         'color': [198, 167, 235],
//         'strength': 70,
//       },
//       {
//         'color': [123, 67, 15],
//         'strength': 80,
//       },
//     ],
//     'version': '1',
//     'releaseDate': '',
//   },

//   {
//     // 'id': '1F811060-A2B6-4F24-8035-AA9C54DD1FE6',
//     'guid': '1F811060-A2B6-4F24-8035-AA9C54DD1FE6',
//     'part': 'lipstick',
//     'range': 'aa',
//     'shadeName': 'bb',
//     'product': 'got2b Powder aa',
//     'texture': 'Powder',
//     'finish': 'Matte',
//     'shades': [
//       {
//         'color': [1, 2, 3],
//         'strength': 100,
//       },
//     ],
//     'version': '1',
//     'releaseDate': '',
//   },
//   {
//     // 'id': '1D3F910A-8A96-47FA-ACB5-0997FD3A0F0B',
//     'guid': '1D3F910A-8A96-47FA-ACB5-0997FD3A0F0B',
//     'part': 'lipliner',
//     'range': 'ccc',
//     'shadeName': 'ccc',
//     'product': 'got2b Powder cc',
//     'texture': 'Powder',
//     'finish': 'Matte',
//     'shades': [
//       {
//         'color': [123, 134, 189],
//         'strength': 100,
//       },
//     ],
//     'version': '1',
//     'releaseDate': '',
//   },
//   {
//     // 'id': '8A67EF34-98B8-4E52-B7DA-5645720C01D9',
//     'guid': '8A67EF34-98B8-4E52-B7DA-5645720C01D9',
//     'part': 'lipstick',
//     'range': 'ddd',
//     'shadeName': 'ddd',
//     'product': 'got2b Powder ddd',
//     'texture': 'Powder',
//     'finish': 'Matte',
//     'shades': [
//       {
//         'color': [0, 0, 0],
//         'strength': 100,
//       },
//     ],
//     'version': '1',
//     'releaseDate': '',
//   },
//   {
//     'guid': 'BCC-16641_20181023_DYE_06_01',
//     'part': 'haircolor',
//     'gtin': '4015000933360',
//     'name': 'Perfect Mousse-800-Mittelblond',
//     'shades': [
//       {
//         'labColor': [31.1, 5.9, 13.4],
//         'rgbColor': [130, 85, 51],
//         'strength': 100,
//         'grey': 68,
//         'shining': 100,
//       },
//     ],
//     'version': '1',
//     'releaseDate': '',
//   },
//   {
//     'guid': 'BCC-16641_20181023_DYE_07_01',
//     'part': 'haircolor',
//     'gtin': '4015000985383',
//     'name': 'Poly Color Creme Haarfarbe-31-Hellblond',
//     'shades': [
//       {
//         'labColor': [47.1, 5.9, 19.7],
//         'rgbColor': [150, 111, 60],
//         'strength': 100,
//         'grey': 51,
//         'shining': 100,
//       },
//     ],
//     'version': '1',
//     'releaseDate': '',
//   },
//   {
//     'guid': 'BCC-16641_20181023_DYE_08_01',
//     'part': 'haircolor',
//     'gtin': '4015000983044',
//     'name': 'Poly Color Creme Haarfarbe-35-Mittelblond',
//     'shades': [
//       {
//         'labColor': [36.2, 5.4, 13.4],
//         'rgbColor': [132, 83, 48],
//         'strength': 100,
//         'grey': 73,
//         'shining': 100,
//       },
//     ],
//     'version': '1',
//     'releaseDate': '',
//   },
//   {
//     'guid': 'DD1D47AE-E337-47D0-A76F-C8D8EF5FCA29',
//     'part': 'nail',
//     'range': 'Paintology - Nail Polish',
//     'shadeName': 'Cloud Nine',
//     'product': 'got2b Nail Polish Studio White',
//     'texture': 'Liquid',
//     'finish': '',
//     'shades': [
//       {
//         'color': [244, 244, 244],
//         'strength': 100,
//       },
//     ],
//     'version': '1',
//     'releaseDate': '',
//   },
//   {
//     'guid': 'DD1D47AE-E337-47D0-A76F-C8D8EF5FCA30',
//     'part': 'nail',
//     'range': 'Paintology - Nail Polish',
//     'shadeName': 'Cloud Nine',
//     'product': 'got2b Nail Polish Studio White',
//     'texture': 'Liquid',
//     'finish': '',
//     'shades': [
//       {
//         'color': [244, 244, 244],
//         'strength': 100,
//       },
//     ],
//     'version': '1',
//     'releaseDate': '',
//   },
//   {
//     'guid': 'A9D817FD-57B7-4123-AAA1-3B549B8AC23F',
//     'part': 'eyebrow',
//     'range': 'Beauty and The Brow - Brow Pencil ',
//     'shadeName': 'Blonde',
//     'product': 'got2b Mechanical Brow Pencil Blonde',
//     'texture': 'Cream',
//     'finish': 'Matte',
//     'shades': [
//       {
//         'color': [164, 140, 110],
//         'strength': 100,
//       },
//     ],
//     'version': '1',
//     'releaseDate': '',
//   },
//   {
//     'guid': 'A9D817FD-57B7-4123-AAA1-3B549B8AC32F',
//     'part': 'eyebrow',
//     'range': 'Beauty and The Brow - Brow Pencil ',
//     'shadeName': 'Blonde',
//     'product': 'got2b Mechanical Brow Pencil Blonde',
//     'texture': 'Cream',
//     'finish': 'Matte',
//     'shades': [
//       {
//         'color': [164, 145, 112],
//         'strength': 100,
//       },
//     ],
//     'version': '1',
//     'releaseDate': '',
//   },
//   {
//     'guid': '2B8288CF-D583-4287-913E-88CC336D1B28',
//     'part': 'foundation',
//     'range': 'Oh My Tan - Shade Adj. Drops',
//     'shadeName': 'OhMyTan',
//     'product': 'got2b Shade Adjusting Drops 03 Dark',
//     'texture': 'Liquid',
//     'finish': 'Shade Adjusting / darkening/tanning',
//     'shades': [
//       {
//         'color': [113, 9, 73],
//         'strength': 100,
//       },
//     ],
//     'version': '1',
//     'releaseDate': '',
//   },
//   {
//     'guid': '0F7DC427-E675-40DB-B2AE-2DDD9718C631',
//     'part': 'foundation',
//     'range': 'Oh My Beam - Shade Adj. Drops',
//     'shadeName': 'OhMyBeam',
//     'product': 'got2b Shade Adjusting Drops 04 Glow',
//     'texture': 'Liquid',
//     'finish': 'Shade Adjusting / highlighting',
//     'shades': [
//       {
//         'color': [28, 211, 187],
//         'strength': 100,
//       },
//     ],
//     'version': '1',
//     'releaseDate': '',
//   },
//   {
//     'guid': 'ECA12DEA-F470-4437-B409-691E492B38BA',
//     'part': 'mascara',
//     'range': 'FABULeyes - Lash Tinting Mascara',
//     'shadeName': 'Black',
//     'product': 'got2b Lash Tinting Mascara Dark',
//     'texture': 'Liquid',
//     'finish': 'Matte',
//     'shades': [
//       {
//         'color': [0, 0, 0],
//         'strength': 100,
//       },
//     ],
//     'version': '1',
//     'releaseDate': '',
//   },
//   {
//     'guid': '27579365-B7A0-4943-8E93-1EBAEC4606F7',
//     'part': 'mascara',
//     'range': 'FABULeyes - Lash Tinting Mascara WP',
//     'shadeName': 'Black Waterproof',
//     'product': 'got2b Lash Tinting Mascara Dark Waterproof',
//     'texture': 'Liquid',
//     'finish': 'Matte',
//     'shades': [
//       {
//         'color': [0, 0, 0],
//         'strength': 100,
//       },
//     ],
//     'version': '1',
//     'releaseDate': '',
//   },
//   {
//     'guid': '08A6EB58-EDEA-407E-9FCB-E3AB5D7DF02F',
//     'part': 'stampliner',
//     'range': 'TWO HIT WONDER - Stampliner',
//     'shadeName': 'Black Star',
//     'product': 'got2b Stampliner Star',
//     'texture': 'Liquid',
//     'finish': 'Matte',
//     'shades': [
//       {
//         'color': [32, 32, 4],
//         'strength': 50,
//       },
//     ],
//     'version': '1',
//     'releaseDate': '',
//   },
//   {
//     'guid': 'F8FADE90-4586-460E-8494-C79C0B10B0C3',
//     'part': 'stampliner',
//     'range': 'TWO HIT WONDER - Stampliner',
//     'shadeName': 'Black Heart',
//     'product': 'got2b Stampliner Heart',
//     'texture': 'Liquid',
//     'finish': 'Matte',
//     'shades': [
//       {
//         'color': [32, 32, 4],
//         'strength': 50,
//       },
//     ],
//     'version': '1',
//     'releaseDate': '',
//   },
//   {
//     'guid': '98603A05-89E3-4439-B43B-71FC4F46C212',
//     'part': 'eyeshadow',
//     'range': 'STAR STRUCK - Eyeshadow Palette',
//     'shadeName': 'No Money, No Honey',
//     'product': 'got2b Large Eyeshadow Palette Nudes',
//     'texture': 'Powder',
//     'finish': 'Pearly & Matte',
//     'shades': [
//       {
//         'color': [179, 171, 150],
//         'strength': 100,
//         'finish': 'matte',
//       },
//       {
//         'color': [156, 130, 86],
//         'strength': 100,
//         'finish': 'matte',
//       },
//       {
//         'color': [117, 93, 66],
//         'strength': 100,
//         'finish': 'pearly',
//       },
//       {
//         'color': [133, 100, 70],
//         'strength': 100,
//         'finish': 'pearly',
//       },
//       {
//         'color': [168, 146, 78],
//         'strength': 100,
//         'finish': 'pearly',
//       },
//       {
//         'color': [83, 94, 86],
//         'strength': 100,
//         'finish': 'pearly',
//       },
//       {
//         'color': [136, 123, 67],
//         'strength': 100,
//         'finish': 'pearly',
//       },
//       {
//         'color': [122, 99, 69],
//         'strength': 100,
//         'finish': 'pearly',
//       },
//       {
//         'color': [87, 71, 53],
//         'strength': 100,
//         'finish': 'pearly',
//       },
//     ],
//     'version': '1',
//     'releaseDate': '',
//   },
//   {
//     'guid': '0031DE9C-E267-4336-AEE8-9C22F0B10D6D',
//     'part': 'eyeshadow',
//     'range': 'STAR STRUCK - Eyeshadow Palette',
//     'shadeName': 'Make Me Blush',
//     'product': 'got2b Large Eyeshadow Palette Blushed',
//     'texture': 'Powder',
//     'finish': 'Pearly & Matte',
//     'shades': [
//       {
//         'color': [179, 170, 154],
//         'strength': 100,
//         'finish': 'matte',
//       },
//       {
//         'color': [156, 135, 133],
//         'strength': 100,
//         'finish': 'pearly',
//       },
//       {
//         'color': [123, 111, 97],
//         'strength': 100,
//         'finish': 'pearly',
//       },
//       {
//         'color': [129, 86, 87],
//         'strength': 100,
//         'finish': 'matte',
//       },
//       {
//         'color': [138, 109, 107],
//         'strength': 100,
//         'finish': 'pearly',
//       },
//       {
//         'color': [107, 99, 96],
//         'strength': 100,
//         'finish': 'matte',
//       },
//       {
//         'color': [141, 120, 109],
//         'strength': 100,
//         'finish': 'pearly',
//       },
//       {
//         'color': [118, 77, 81],
//         'strength': 100,
//         'finish': 'pearly',
//       },
//       {
//         'color': [85, 72, 67],
//         'strength': 100,
//         'finish': 'pearly',
//       },
//     ],
//     'version': '1',
//     'releaseDate': '',
//   },
//   {
//     'guid': 'AB425B66-8436-421C-B0F5-A8F66ACB96BA',
//     'part': 'eyeshadow',
//     'range': 'STAR STRUCK - Eyeshadow Palette',
//     'shadeName': 'Ready For Red',
//     'product': 'got2b Large Eyeshadow Palette Berry',
//     'texture': 'Powder',
//     'finish': 'Pearly & Matte',
//     'shades': [
//       {
//         'color': [204, 187, 173],
//         'strength': 100,
//         'finish': 'pearly',
//       },
//       {
//         'color': [135, 83, 87],
//         'strength': 100,
//         'finish': 'pearly',
//       },
//       {
//         'color': [139, 75, 92],
//         'strength': 100,
//         'finish': 'pearly',
//       },
//       {
//         'color': [160, 107, 108],
//         'strength': 100,
//         'finish': 'matte',
//       },
//       {
//         'color': [175, 135, 116],
//         'strength': 100,
//         'finish': 'pearly',
//       },
//       {
//         'color': [174, 103, 101],
//         'strength': 100,
//         'finish': 'matte',
//       },
//       {
//         'color': [141, 65, 70],
//         'strength': 100,
//         'finish': 'matte',
//       },
//       {
//         'color': [166, 117, 128],
//         'strength': 100,
//         'finish': 'pearly',
//       },
//       {
//         'color': [100, 82, 79],
//         'strength': 100,
//         'finish': 'pearly',
//       },
//     ],
//     'version': '1',
//     'releaseDate': '',
//   },
//   {
//     'guid': '184BEDC2-333A-4D52-956C-DC78C8C065B9',
//     'part': 'contour',
//     'range': 'SCULPT FINITION - Contouring Palette',
//     'shadeName': 'Light Medium',
//     'product': 'got2b Contouring Palette Light Medium',
//     'texture': 'Powder',
//     'finish': 'Matte',
//     'shades': [
//       {
//         'color': [235, 219, 205],
//         'strength': 100,
//       },
//       {
//         'color': [212, 186, 167],
//         'strength': 100,
//       },
//       {
//         'color': [172, 146, 127],
//         'strength': 100,
//       },
//     ],
//     'version': '1',
//     'releaseDate': '',
//   },
// ];

export const createProduct = (data) => {
  const allowedAudiences = _.split(data.allowedAudiences, '\n').map((item) => item.trim());
  const allowedStyles = _.split(data.allowedStyles, '\n').map((item) => item.trim());
  return post('/products', { guid: uuidv4().toUpperCase(), ...data, allowedAudiences, allowedStyles });

  // if (process.env.NODE_ENV !== 'production') {
  //   const uuidgen = uuidv4();
  //   mockupProducts.push({
  //     id: uuidgen,
  //     guid: uuidgen,
  //     'version': 1,
  //     releaseDate: '',
  //     'shades': [
  //       {
  //         'color': [0, 0, 0],
  //         'strength': 0,
  //       },
  //     ],
  //     ...data,
  //   });
  //   return Promise.resolve();
  // }
  // return put('/product/', data);
};

export const deleteProduct = (id) => {
  // if (process.env.NODE_ENV !== 'production') {
  //   _.remove(mockupProducts, { guid: id });
  //   return Promise.resolve();
  // }
  return del(`/products/${id}`);
};

export const editProduct = ({ id, ...rest }) => {
  const allowedAudiences = _.split(rest.allowedAudiences, '\n').map((item) => item.trim());
  const allowedStyles = _.split(rest.allowedStyles, '\n').map((item) => item.trim());
  return put(`/products/${id}`, { ...rest, allowedAudiences, allowedStyles });
};

export const editProductShade = ({ id, ...rest }) => {
  const index = rest.index;
  const shades = rest.shades[index];
  return put(`/products/${id}/shades/${index}`, shades);
};

export const getProductShades = (id) => {
  return get(`/products/${id}`);
};

export const getProduct = (id) => {
  return get(`/products/${id}`);
};

// export const createProduct = (data) => {
//   console.log(...{ data });
//   // return post('/213213', data);
// };

// export const editProduct = ({ id, ...rest }) => {
//   console.log(id, { ...rest });
//   // return post(`${id}`, { ...rest });
// };

// export const deleteProduct = ({ id }) => {
//   console.log(id);
//   // return post(`${id}`);
// };

// export const getProduct = () => {
//   return get('products');
// };

export const getProducts = (pageParams) => {
  const query = pageParams
    ? `?page=${pageParams.page ? pageParams.page + 1 : 1}&pageSize=${pageParams.pageSize ? pageParams.pageSize : 10}${
        pageParams.part !== undefined && pageParams.part !== '' ? '&part=' + pageParams.part : ''
      }${pageParams.name !== undefined && pageParams.name !== '' ? '&name=' + pageParams.name : ''} `
    : '';
  return get(`/products${query}`);
};

export const fetchProductAmount = () => {
  return get(`/products/amount`);
};
