const initDate = {};

export default (state = initDate, { type, payload }) => {
  switch (type) {
    case 'SET_FORM_DATA':
      return payload;
    default:
      return state;
  }
};
