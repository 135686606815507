import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Alert from 'components/Alert/Alert';
import Copyright from 'components/Copyright/Copyright';
import Loading from 'components/Loading/Loading';
import NavBar from 'components/NavBar/NavBar';
import { navigationDrawerWidth } from 'config';
import React, { useEffect, useState } from 'react';

const DefaultLayout = withStyles((theme) => ({
  content: {
    flexGrow: 1,
    marginLeft: 0,
    marginTop: theme.spacing(8),
    padding: theme.spacing(3),
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    minHeight: 'calc(100vh - 112px)',
    display: 'flex',
    flexDirection: 'column',
  },
  contentShift: {
    marginLeft: navigationDrawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}))(({ children, classes, title, ...rest }) => {
  const [drawerOpen, setDrawerOpen] = useState(true);

  useEffect(() => {
    document.title = 'AR-Makeup Console';
  }, [title]);

  return (
    <div>
      <NavBar
        drawerOpen={drawerOpen}
        onDrawerOpen={() => setDrawerOpen(true)}
        onDrawerClose={() => setDrawerOpen(false)}
        title={title}
        {...rest}
      />
      <main
        className={classnames(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
        <Loading drawerOpen={drawerOpen} />
        {children}
        <Copyright />
      </main>
      <Alert />
    </div>
  );
});

export default DefaultLayout;
