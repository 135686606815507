import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import TabPanel from 'components/TabPanel';
import GeneralTab from 'components/GeneralTab';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    marginTop: theme.spacing(3),
  },
  tab: {
    fontSize: 14,
  },
  tabPanel: {
    padding: theme.spacing(3),
  },
}));

export default (props) => {
  const classes = useStyles();
  const { data, tabs, ...restList } = props;
  const [activeTab, setActiveTab] = React.useState(0);
  const handleChange = (event, activeTab) => {
    setActiveTab(activeTab);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          aria-label="simple tabs"
          indicatorColor="secondary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          value={activeTab}
          onChange={handleChange}
        >
          {tabs.map(({ label, name }) => {
            return <Tab className={classes.tab} id={name} key={name} label={label} />;
          })}
        </Tabs>
      </AppBar>
      {tabs.map(({ component: Component = GeneralTab, name, ...rest }) => {
        return (
          <TabPanel hidden={tabs[activeTab].name !== name} key={name}>
            <Component data={name === 'general' ? data : data[name]} {...rest} {...restList} />
          </TabPanel>
        );
      })}
    </div>
  );
};
