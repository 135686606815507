import { Delete, Edit, Refresh } from '@material-ui/icons';
import Single from 'components/Form/Single';
import Multi from 'components/Form/Multi';
import DetailsPage from 'pages/DetailsPage';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as action from 'redux/actions';
import * as yup from 'yup';

const StyleDetailsPage = ({ data, dispatch }) => {
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    dispatch(action.fetchStyle(id));
    return () => {
      dispatch(action.resetStyle());
    };
  }, [dispatch, id]);

  const config = {
    category: 'masterdata',
    modals: [
      {
        name: 'editStyle',
        fields: [
          {
            name: 'part',
            component: Single,
            label: 'Part',
            required: true,
          },
          {
            name: 'name',
            autoFocus: true,
            component: Single,
            label: 'Name',
            required: true,
            validation: yup.string().required('Name is required').min(2, 'Name should be of minimum 2 characters'),
          },
          {
            name: 'config',
            component: Multi,
            label: 'Config',
          },
        ],
        onSubmit: (data) => {
          dispatch(action.editStyle(data, 'details'));
        },
        title: 'Edit',
      },
      {
        name: 'deleteStyle',
        contentText: 'Are you sure to delete the style?',
        fields: [
          {
            name: 'part',
            component: Single,
            label: 'Part',
            readonly: true,
          },
          {
            name: 'name',
            component: Single,
            label: 'Name',
            readonly: true,
          },
        ],
        onSubmit: () => {
          dispatch(
            action.deleteStyle(id, {}, () => {
              history.push('/master data/styles');
            }),
          );
        },
        title: 'Delete',
      },
    ],
    tabs: [
      {
        name: 'general',
        label: 'General',
        fields: [
          { name: 'guid', label: 'GUID' },
          { name: 'part', label: 'Part' },
          { name: 'name', label: 'Name' },
          { name: 'config', label: 'Config', span: 6, multiline: true },
          { name: 'createdDate', label: 'Created Date' },
          { name: 'lastModifiedDate', label: 'Last Modified Date' },
        ],
      },
    ],
    title: 'Style Details',
    toolbarItems: [
      {
        name: 'refresh',
        icon: Refresh,
        onClick: () => {
          dispatch(action.fetchStyle(id));
        },
        text: 'Refresh',
      },
      {
        name: 'edit',
        icon: Edit,
        onClick: () => {
          dispatch(action.openEditStyleModal(id));
        },
        text: 'Edit',
      },
      {
        name: 'delete',
        icon: Delete,
        onClick: () => {
          dispatch(action.openDeleteStyleModal(data));
        },
        text: 'Delete',
      },
    ],
  };

  return <DetailsPage config={config} data={data} />;
};

const mapStateToProps = ({ style }) => ({
  data: style,
});

export default connect(mapStateToProps)(StyleDetailsPage);
