import { del, get, post, put } from 'api';
import { v4 as uuidv4 } from 'uuid';

export const createStyle = (data) => {
  let config = JSON.parse(data.config);
  return post('/styles', { guid: uuidv4().toUpperCase(), ...data, config });
};

export const deleteStyle = (id) => {
  return del(`/styles/${id}`);
};

export const editStyle = ({ id, ...rest }) => {
  delete rest.lastModifiedDate;
  delete rest.createdDate;
  let config = {};
  config = JSON.parse(rest.config);
  return put(`/styles/${id}`, { ...rest, config });
};

export const getStyle = (id) => {
  return get(`/styles/${id}`);
};

export const getStyles = (pageParams) => {
  const query = pageParams
    ? `?page=${pageParams.page ? pageParams.page + 1 : 1}&pageSize=${pageParams.pageSize ? pageParams.pageSize : 10}${
        pageParams.part !== undefined && pageParams.part !== '' ? '&part=' + pageParams.part : ''
      } `
    : '';
  return get(`/styles${query}`);
};
