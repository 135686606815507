import React, { useEffect } from 'react';
import NavIndexPage from './../NavIndexPage';
import { Style, Storage } from '@material-ui/icons';

const MasterDataIndexPage = () => {
  useEffect(() => {
    document.title = 'AR-Makeup Console';
  });

  const config = {
    category: 'masterdata',
    categoryHierarchy: ['masterdata'],
    title: 'Master Data',
    items: [
      {
        name: 'Products',
        title: 'Products',
        description:
          'Products management page. where you can create new product based on features. search existing product, display product details, edit or delete product, etc.',
        icon: Storage,
        link: '/haircolor',
      },
      {
        name: 'Styles',
        title: 'Styles',
        description:
          'Styles management page. where you can create new style, search existing style, display style details, edit or delete style, etc.',
        icon: Style,
        link: '/master data/styles',
      },
    ],
  };

  return <NavIndexPage config={config}>System Index Page</NavIndexPage>;
};

export default MasterDataIndexPage;
