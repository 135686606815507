import { Button, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const SignInButton = withStyles(() => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))(({ classes, loading, login }) => {
  return (
    <div className={classes.wrapper}>
      <Button onClick={() => login()} color="primary" disabled={loading} variant="contained">
        Sign in with Azure AD
      </Button>
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
});

export default SignInButton;
