import { useMsal } from '@azure/msal-react';
import {
  AppBar,
  Badge,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { alpha, withStyles } from '@material-ui/core/styles';
import {
  AccountCircle,
  // AccountTree,
  ChevronLeft,
  ExpandLess,
  ExpandMore,
  Menu as MenuIcon,
  Notifications,
  Search,
  Settings,
  SettingsApplications,
  DataUsage,
  Style,
  // Home,
  AccessTime,
  Storage,
  Colorize,
  Visibility,
  EmojiEmotions,
  PanTool,
  Palette,
  Face,
  BarChart,
} from '@material-ui/icons';
import classnames from 'classnames';
import { navigationDrawerWidth } from 'config';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const NavBar = withStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${navigationDrawerWidth}px)`,
    marginLeft: navigationDrawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    textTransform: 'capitalize',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: navigationDrawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: navigationDrawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  category: {
    padding: theme.spacing(0),
  },
  menuItem: {
    paddingLeft: theme.spacing(3),
  },
  menuSubItem: {
    paddingLeft: theme.spacing(5),
  },
  menuMakeupSubItem: {
    paddingLeft: theme.spacing(7),
  },
  menuMakeupItems: {
    paddingLeft: theme.spacing(9),
  },
}))(
  ({
    category,
    categoryHierarchy = [],
    classes,
    drawerOpen,
    onDrawerClose,
    onDrawerOpen,
    superUser = false,
    title,
  }) => {
    const { instance } = useMsal();
    const [anchorEl, setAnchorEl] = useState(null);
    const [foldingProductMenu, setFoldingProductMenu] = useState(categoryHierarchy);
    const [foldingMenu, setFoldingMenu] = useState(category);

    const toggleMenuFolding = (name, changeMaster = false) => {
      if (foldingProductMenu.includes(name)) {
        setFoldingProductMenu((existingFoldingMenu) =>
          existingFoldingMenu.filter((foldingMenus, i) => i !== existingFoldingMenu.indexOf(name)),
        );
        if (changeMaster) {
          setFoldingMenu(null);
        }
      } else {
        setFoldingProductMenu((existingFoldingMenu) => [...existingFoldingMenu, name]);
        if (changeMaster) {
          setFoldingMenu(name);
        }
      }
    };

    const signOut = () => {
      instance
        .logoutRedirect({
          postLogoutRedirectUri: '/',
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem onClick={signOut}>Sign out</MenuItem>
      </Menu>
    );

    return (
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={classnames(classes.appBar, {
            [classes.appBarShift]: drawerOpen,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => onDrawerOpen()}
              edge="start"
              className={classnames(classes.menuButton, drawerOpen && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Typography className={classes.title} variant="h6" noWrap>
              {title}
            </Typography>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <Search />
              </div>
              <InputBase
                placeholder="Search"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <IconButton aria-label="show 17 new notifications" color="inherit" component={Link} to="/notification">
                <Badge badgeContent={17} color="secondary">
                  <Notifications />
                </Badge>
              </IconButton>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMenu}
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={() => onDrawerClose()}>{<ChevronLeft />}</IconButton>
          </div>

          <List className={classes.category}>
            <ListItem
              button
              onClick={() => toggleMenuFolding('Home', true)}
              component={Link}
              selected={title === 'Home'}
              to="/"
            >
              <ListItemIcon>{<BarChart />}</ListItemIcon>
              <ListItemText primary="Home" />
              {/* {foldingProductMenu.includes('dashboard') || foldingMenu === 'dashboard' ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )} */}
            </ListItem>
            <ListItem
              button
              onClick={() => toggleMenuFolding('masterdata', true)}
              component={Link}
              selected={title === 'Master Data'}
              to="/master data"
            >
              <ListItemIcon>{<DataUsage />}</ListItemIcon>
              <ListItemText primary="Master Data" />
              {foldingProductMenu.includes('masterdata') || foldingMenu === 'masterdata' ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItem>
            <Divider />
            <Collapse
              in={foldingProductMenu.includes('masterdata') || foldingMenu === 'masterdata'}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.menuItem}
                  onClick={() => toggleMenuFolding('products')}
                  selected={title === 'Products'}
                >
                  <ListItemIcon>{<Storage />}</ListItemIcon>
                  <ListItemText primary="Products" />
                  {foldingProductMenu.includes('products') ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Divider />
                <Collapse in={foldingProductMenu.includes('products')} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      className={classes.menuSubItem}
                      onClick={() => toggleMenuFolding('haircolor')}
                      component={Link}
                      selected={title === 'Haircolor' || title === 'Haircolor Details'}
                      to="/haircolor"
                    >
                      <ListItemIcon>{<Face />}</ListItemIcon>
                      <ListItemText primary="Haircolor" />
                    </ListItem>
                    <Divider />
                    <ListItem
                      button
                      className={classes.menuSubItem}
                      onClick={() => toggleMenuFolding('makeup')}
                      selected={title === 'makeup'}
                    >
                      <ListItemIcon>{<Palette />}</ListItemIcon>
                      <ListItemText primary="Makeup" />
                      {foldingProductMenu.includes('makeup') ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Divider />
                    <Collapse in={foldingProductMenu.includes('makeup')} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          className={classes.menuMakeupSubItem}
                          onClick={() => toggleMenuFolding('lip')}
                          selected={title === 'Lips'}
                        >
                          <ListItemIcon>{<Colorize />}</ListItemIcon>
                          <ListItemText primary="Lips" />
                          {foldingProductMenu.includes('lip') ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Divider />
                        <Collapse in={foldingProductMenu.includes('lip')} timeout="auto" unmountOnExit>
                          <ListItem
                            button
                            className={classes.menuMakeupItems}
                            component={Link}
                            selected={title === 'Lipstick' || title === 'Lipstick Details'}
                            to="/lipstick"
                          >
                            <ListItemIcon>{<AccessTime />}</ListItemIcon>
                            <ListItemText primary="Lipstick" />
                          </ListItem>
                          <Divider />
                          <ListItem
                            button
                            className={classes.menuMakeupItems}
                            component={Link}
                            selected={title === 'Lipliner' || title === 'Lipliner Details'}
                            to="/lipliner"
                          >
                            <ListItemIcon>{<AccessTime />}</ListItemIcon>
                            <ListItemText primary="Lipliner" />
                          </ListItem>
                          <Divider />
                        </Collapse>
                        <ListItem
                          button
                          className={classes.menuMakeupSubItem}
                          onClick={() => toggleMenuFolding('eye')}
                          selected={title === 'Eyes'}
                        >
                          <ListItemIcon>{<Visibility />}</ListItemIcon>
                          <ListItemText primary="Eyes" />
                          {foldingProductMenu.includes('eye') ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Divider />
                        <Collapse in={foldingProductMenu.includes('eye')} timeout="auto" unmountOnExit>
                          <ListItem
                            button
                            className={classes.menuMakeupItems}
                            component={Link}
                            selected={title === 'Eyeshadow' || title === 'Eyeshadow Details'}
                            to="/eyeshadow"
                          >
                            <ListItemIcon>{<AccessTime />}</ListItemIcon>
                            <ListItemText primary="Eyeshadow" />
                          </ListItem>
                          <Divider />
                          <ListItem
                            button
                            className={classes.menuMakeupItems}
                            component={Link}
                            selected={title === 'Eyeliner' || title === 'Eyeliner Details'}
                            to="/eyeliner"
                          >
                            <ListItemIcon>{<AccessTime />}</ListItemIcon>
                            <ListItemText primary="Eyeliner" />
                          </ListItem>
                          <Divider />
                          <ListItem
                            button
                            className={classes.menuMakeupItems}
                            component={Link}
                            selected={title === 'Eyebrow' || title === 'Eyebrow Details'}
                            to="/eyebrow"
                          >
                            <ListItemIcon>{<AccessTime />}</ListItemIcon>
                            <ListItemText primary="Eyebrow" />
                          </ListItem>
                          <Divider />
                          <ListItem
                            button
                            className={classes.menuMakeupItems}
                            component={Link}
                            selected={title === 'Mascara' || title === 'Mascara Details'}
                            to="/mascara"
                          >
                            <ListItemIcon>{<AccessTime />}</ListItemIcon>
                            <ListItemText primary="Mascara" />
                          </ListItem>
                          <Divider />
                          <ListItem
                            button
                            className={classes.menuMakeupItems}
                            component={Link}
                            selected={title === 'Stampliner' || title === 'Stampliner Details'}
                            to="/stampliner"
                          >
                            <ListItemIcon>{<AccessTime />}</ListItemIcon>
                            <ListItemText primary="Stampliner" />
                          </ListItem>
                          <Divider />
                          <ListItem
                            button
                            className={classes.menuMakeupItems}
                            component={Link}
                            selected={title === 'Eyecolor' || title === 'Eyecolor Details'}
                            to="/eyecolor"
                          >
                            <ListItemIcon>{<AccessTime />}</ListItemIcon>
                            <ListItemText primary="Eyecolor" />
                          </ListItem>
                          <Divider />
                        </Collapse>
                        <ListItem
                          button
                          className={classes.menuMakeupSubItem}
                          onClick={() => toggleMenuFolding('face')}
                          selected={title === 'Face'}
                        >
                          <ListItemIcon>{<EmojiEmotions />}</ListItemIcon>
                          <ListItemText primary="Face" />
                          {foldingProductMenu.includes('face') ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Divider />
                        <Collapse in={foldingProductMenu.includes('face')} timeout="auto" unmountOnExit>
                          <ListItem
                            button
                            className={classes.menuMakeupItems}
                            component={Link}
                            selected={title === 'Blush' || title === 'Blush Details'}
                            to="/blush"
                          >
                            <ListItemIcon>{<AccessTime />}</ListItemIcon>
                            <ListItemText primary="Blush" />
                          </ListItem>
                          <Divider />
                          <ListItem
                            button
                            className={classes.menuMakeupItems}
                            component={Link}
                            selected={title === 'Foundation' || title === 'Foundation Details'}
                            to="/foundation"
                          >
                            <ListItemIcon>{<AccessTime />}</ListItemIcon>
                            <ListItemText primary="Foundation" />
                          </ListItem>
                          <Divider />
                          <ListItem
                            button
                            className={classes.menuMakeupItems}
                            component={Link}
                            selected={title === 'Contour' || title === 'Contour Details'}
                            to="/contour"
                          >
                            <ListItemIcon>{<AccessTime />}</ListItemIcon>
                            <ListItemText primary="Contour" />
                          </ListItem>
                          <Divider />
                        </Collapse>
                        <ListItem
                          button
                          className={classes.menuMakeupSubItem}
                          component={Link}
                          selected={title === 'Nails' || title === 'Nail Details'}
                          to="/nail"
                        >
                          <ListItemIcon>{<PanTool />}</ListItemIcon>
                          <ListItemText primary="Nails" />
                        </ListItem>
                        <Divider />
                      </List>
                    </Collapse>
                  </List>
                </Collapse>
              </List>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.menuItem}
                  component={Link}
                  selected={title === 'Styles'}
                  to="/master data/styles"
                >
                  <ListItemIcon>{<Style />}</ListItemIcon>
                  <ListItemText primary="Styles" />
                </ListItem>
              </List>
            </Collapse>
          </List>
          <Divider />
          {/* <List className={classes.category}>
          <ListItem button component={Link} selected={title === 'Reports'} to="/reports">
            <ListItemIcon>{<AccountTree />}</ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItem>
        </List> */}
          <Divider />
          {superUser && (
            <Fragment>
              <List className={classes.category}>
                <ListItem
                  button
                  onClick={() => toggleMenuFolding('system', true)}
                  component={Link}
                  selected={title === 'System'}
                  to="/system"
                >
                  <ListItemIcon>{<Settings />}</ListItemIcon>
                  <ListItemText primary="System" />
                  {foldingMenu === 'system' ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={foldingMenu === 'system'} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      className={classes.menuItem}
                      component={Link}
                      selected={title === 'Audiences'}
                      to="/system/audiences"
                    >
                      <ListItemIcon>{<SettingsApplications />}</ListItemIcon>
                      <ListItemText primary="Audiences" />
                    </ListItem>
                  </List>
                </Collapse>
              </List>
              <Divider />
            </Fragment>
          )}
        </Drawer>
      </div>
    );
  },
);

const mapStateToProps = ({ loginInfo }) => ({
  superUser: () => {
    if (process.env.NODE_ENV === 'development') {
      return true;
    }

    return loginInfo.super;
  },
});

export default connect(mapStateToProps)(NavBar);
